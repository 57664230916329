import React from 'react';

type Props = {
    text: any;
    style?: any
    level?: any
    className?: any
}

const Title = (props: Props) => {
    const { text, ...rest } = props
    return <span style={{color:"#003157"}} {...rest} >{text}</span>

}

export default Title;