import axios from "axios";

const API_URL = process.env.REACT_APP_API || window.ENV?.REACT_APP_API;
const instance = axios.create({
  baseURL: API_URL,
});

instance.interceptors.request.use(
  async function (config) {
    const token =  localStorage.getItem("token");
    if (token) {
      config.headers = {
        "authorization": token,
      };
    }
    return config;
  },

  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => checkStatus(response),
  (error) => Promise.resolve(checkStatus(error))
);

function checkStatus(response) {
  if (response.response) {
    if (response.response?.data?.message == 'DUPLICATE_VOUCHER_CODE')
      return {
        status: 'ERROR',
        message: 'Mã voucher đã tồn tại'
      }
  }
  // const {status} = response
  // const { url } = response.config;

  // if(status > 201 ) {
  //   console.log(ENDPOINT_RETURN_DATA.includes(url) ? "dung" : "sai")
  //   return ENDPOINT_RETURN_DATA.includes(url) && response.data
  // }
  return response.data;
}
export default instance;
