/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { memo, useEffect, useState } from 'react';
import { FButton, FModal } from '../../../components/element';
import {
  Form,
  Input,
  DatePicker,
  InputNumber,
  Modal,
  Row,
  Col,
  Select,
  Spin,
} from 'antd';
import moment from 'moment';
import { useNotify } from '../../../hooks/useNotify';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useOrder } from '../../../middleware/state/order/hooks';
import { ORDER, ORDER_STATUS } from '../../../constant';
import { useVoucher } from '../../../middleware/state/voucher/hooks';
const { Option } = Select;

dayjs.extend(customParseFormat);

type Props = {
  handleCancel: () => void;
  handleOk?: () => void;
  isModalOpen: boolean;
  action: string;
  id: string;
};

function ModalActionOrder(props: Props) {
  const { handleCancel, isModalOpen, action, id } = props;
  const paramsVoucher = {
    limit: 1000,
    offset: 1,
    del: true,
  }
  const { onGetList, vouchers, onSearchVoucher } = useVoucher();
  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [reqConfirm,setReqConfirm] = useState(null)

  const [form] = Form.useForm();
  const {
    onCreateOrder,
    onDestroy,
    onUpdateOrder,
    onGetDetail,
    detail,
    isLoading,
    isSuccessCreate,
    onGetPackage,
    package_order,
  } = useOrder();
  const {
    createdDate,
    customerEmail,
    servicePackage,
    servicePackageAmountTotal,
    status,
    saleId,
    voucherId = "",
    customerPhone
  } = detail;
  const { showNotify, contextHolder } = useNotify();

  const formatDate = (e: string) => {
    return moment(e).format('YYYY-MM-DDTHH:mm:ss.000Z');
  };
  const onFinish = (values: any) => {
    const req = {
      ...values,
      id: id.length !== 0 ? id : "",
      voucherId: values?.voucherId || "",
      createdDate: formatDate(values.createdDate),
      servicePackageAmount: package_order.filter(
        (item) => item.name === values.servicePackage,
      )[0]?.price || null,
    };

    if( id.length !== 0 && values?.status === ORDER.ORDER_CANCELED) {
      setReqConfirm(req)
      setIsModalConfirm(true)
      return
    }

    id.length === 0 ? onCreateOrder(req) : onUpdateOrder(req);
  };

  useEffect(() => {
    if (isSuccessCreate) {
      showNotify(
        'success',
        `${action === 'create' ? 'Tạo' : 'Chỉnh sửa'}  Order thành công`,
      );
      handleCancel();
      onDestroy();
      form.resetFields();
    }
  }, [isSuccessCreate]);

  useEffect(() => {
    form.resetFields();
    if (id.length !== 0) {
      onGetDetail(id);
      return;
    }
  }, [id]);

  useEffect(() => {
    onGetList(paramsVoucher);
  }, []);

  useEffect(() => {
    onGetPackage();
  }, []);
  

  useEffect(() => {
    if (id.length !== 0 && detail && Object.keys(detail).length !== 0)
      form.setFieldsValue({
        customerEmail: customerEmail,
        servicePackage: servicePackage,
        servicePackageTotalAmount: servicePackageAmountTotal,
        status: status,
        saleId: saleId,
        voucherId: voucherId,
        customerPhone: customerPhone,
        createdDate: moment(createdDate),
      });
  }, [detail]);

  const closeModal = () => {
    handleCancel();
    form.resetFields();
  };

  const disabledDate = (current: any) => {
    return current && current > moment().endOf('day');
  };


  return (
    <Modal
      open={isModalOpen}
      title={action === 'create' ? 'Tạo Order mới' : 'Chỉnh sửa Order'}
      footer={null}
      style={{ borderRadius: 10 }}
      onCancel={closeModal}
      maskClosable={false}
    >
      <>
        {contextHolder}
        {isLoading ? <Spin spinning={true} className="d-flex justify-content-center"/> :<Form form={form} layout="vertical" onFinish={onFinish}>
          <Row align="middle" justify="center">
            <Col span={18}>
              <Row>
                <Col span={24} className="mb-2">
                  <span>Trạng thái</span>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="status"
                        initialValue={id.length === 0 && 'ORDER_IN_PROGRESS'}
                      >
                        <Select placeholder="Trạng thái">
                          {ORDER_STATUS.map(
                            (item, idx) =>
                              item.id !== 0 && (
                                <Option key={idx} value={item.value}>
                                  {item.label}
                                </Option>
                              ),
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col span={24} className="mb-2">
                  <span>Khách hàng (*)</span>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="customerEmail"
                        rules={[
                          {
                            required: true,
                            message: 'Email không được bỏ trống',
                          },
                          {
                            type: 'email',
                            message: 'Email không hợp lệ',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col span={24} className="mb-2">
                  <span>Số điện thoại (*)</span>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="customerPhone"
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value: string) {
                              if (!value)
                                return Promise.reject(new Error('Số điện thoại không được trống'));
                              if (value?.toString().length != 10)
                                return Promise.reject(new Error('Số điện thoại phải có 10 chữ số'));
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col span={24} className="mb-2">
                  <span>Dịch vụ</span>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="servicePackage"
                        initialValue={package_order[0]?.name}
                      >
                        <Select
                          placeholder="Dịch vụ"
                        >
                          {package_order.length !== 0 &&
                            package_order.map((item: any, idx: number) => (
                              <Option key={idx} value={item.name}>
                                {item.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col span={24} className="mb-2">
                  <span>Voucher</span>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="voucherId">
                        <Select
                          placeholder="Chọn voucher"
                          showSearch
                          allowClear
                          optionFilterProp="children"
                          onSearch={(e) =>
                            onSearchVoucher({
                              codes: e.length !== 0 ? [e] : null,
                            })
                          }
                        >
                          {vouchers.map((item: any, idx) => {
                            const isExpired = moment(new Date()).isBefore(moment(item.expiredAt))
                            if (action === 'create') {
                              return !item.del && isExpired && (
                                <Option key={idx} value={item.id}>
                                  {item.code}
                                </Option>
                              )
                            }
                            else {
                              if (item?.del && voucherId == item?.id) {
                                return <Option key={idx} value={item.id}>
                                  {item.code}
                                </Option>
                              }
                              if (!item.del && isExpired) {
                                return <Option key={idx} value={item.id}>
                                  {item.code}
                                </Option>
                              }
                            }
                          })}
                          
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={24} className="mb-2">
                  <span>Ngày tạo đơn (*)</span>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="createdDate"
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng chọn ngày tạo đơn',
                          },
                        ]}
                      >
                        <DatePicker
                          format="YYYY-MM-DD"
                          style={{ width: '100%' }}
                          disabledDate={disabledDate}
                          placeholder="Chọn ngày"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col span={24} className="mb-2">
                  <span>Tổng tiền (VND) (*)</span>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="servicePackageTotalAmount"
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập Tổng tiền',
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="Nhập số tiền"
                          className="w-100"
                          formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="d-flex justify-content-end">
            <FButton
              text={id.length === 0 ? 'Xác nhận' : 'Chỉnh sửa'}
              htmlType="submit"
              loading={isSuccessCreate}
            />
          </div>
        </Form>}
      
        {isModalConfirm && (
          <FModal
            isModalOpen={isModalConfirm}
            handleOk={() => {
              onUpdateOrder(reqConfirm)
            }}
            handleCancel={() => setIsModalConfirm(false)}
          >
            <h6 style={{ color: '#D6190B', fontWeight: 'bold' }}>Cảnh báo</h6>
            <span style={{ color: '#D6190B', marginTop: 20 }}>
              Bạn muốn huỷ đơn hàng này?
            </span>
          </FModal>
        )}
      </>
    </Modal>
  );
}

export default memo(ModalActionOrder);
