import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  IPaging,
  ReqCreateSaleMan,
  Saleman,
} from "middleware/state/saleman/type";
import {
  salemans,
  postSaleman,
  verifySaleman,
  search,
  detail,
  update,
  deleteSaleman,
} from "./api";


const default_profile = {
  userEmail: "",
  wixUserName: "",
  userPhone: "",
  commissionRate: 0,
  status: "",
  personalId: "",
  address: "",
  zaloPhone: "",
  facebookLink: "",
  accountName: "",
  accountNumber: "",
  accountBankName: "",
  accountBankBranch: "",
  wixUserId: "",
  cooperationStartAt: "",
  cooperationEndAt: "",
  personalLicenseAt: "",
  personalLicensePlace: "",
  isWorkingAtFIDT: false
}

const SalemanState: Saleman = {
  id: "",
  salemans: [],
  isLoading: false,
  errorMessage: "",
  isCheck: "checking",
  total: 0,
  isSuccess: false,
  isSuccessCreate: false,
  isSuccessDelete: false,
  detail: {
    userEmail: "",
    wixUserName: "",
    userPhone: "",
    commissionRate: 0,
    status: "",
    personalId: "",
    address: "",
    zaloPhone: "",
    facebookLink: "",
    accountName: "",
    accountNumber: "",
    accountBankName: "",
    accountBankBranch: "",
    wixUserId: "",
    cooperationStartAt: "",
    cooperationEndAt: "",
    personalLicenseAt: "",
    personalLicensePlace: "",
    isWorkingAtFIDT: false,
  },
  profile: default_profile,
};

const insertSTT = (data: any) => {
  const newArr = data.map((item: any, index: number) => {
    return {
      ...item,
      stt: index + 1,
    };
  }).filter((ele:any)=>ele.del === false);

  return newArr;
};

const slice = createSlice({
  name: "saleman",
  initialState: SalemanState,
  reducers: {
    destroy: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isSuccessCreate = false;
      state.isSuccessDelete = false;
      state.isCheck = "checking";
    },
    clearProfile:(state) => {
        state.profile = default_profile
        state.id = ""
    }
  },
  extraReducers: (builder) => {
    builder.addCase(saleman.pending, (state) => {
      state.isLoading = true;
      state.errorMessage = ""
    });

    builder.addCase(saleman.fulfilled, (state, action) => {
      state.isLoading = false;
      const { data } = action.payload;
      state.salemans = insertSTT(data);
      state.total = action.payload.total;
    });
    builder.addCase(saleman.rejected, (state, action: any) => {
      state.isLoading = false;
    });

    builder.addCase(detailSalesman.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(detailSalesman.fulfilled, (state, action) => {
      state.isLoading = false;
      state.detail = action.payload;
    });
    builder.addCase(detailSalesman.rejected, (state, action: any) => {
      state.isLoading = false;
    });

    builder.addCase(getProfile.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.isLoading = false;
      state.profile = action.payload;
    });
    builder.addCase(getProfile.rejected, (state, action: any) => {
      state.isLoading = false;
      state.isCheck = "incorrect"
    });

    builder.addCase(createSaleman.pending, (state) => {
      state.isLoading = true;
      state.isSuccessCreate = false;
      state.errorMessage = ""
    });

    builder.addCase(createSaleman.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccessCreate = true;
    });

    builder.addCase(createSaleman.rejected, (state, action: any) => {
      state.isLoading = false;
      state.isSuccessCreate = false;
      if(action?.error?.message) {
        if(action?.error?.message === "USER_EMAIL_EXISTED")
        state.errorMessage = "Email này đã được sử dụng."
        return
      }
    });

    builder.addCase(updateSaleman.pending, (state) => {
      state.isLoading = true;
      state.isSuccessCreate = false;
    });

    builder.addCase(updateSaleman.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccessCreate = true;
    });

    builder.addCase(updateSaleman.rejected, (state, action: any) => {
      state.isLoading = false;
      state.isSuccessCreate = false;
      if(action?.error?.message) {
        if(action?.error?.message === "USER_EMAIL_EXISTED")
        state.errorMessage = "Email này đã được sử dụng."
        return
      }
    });

    builder.addCase(searchSalesman.pending, (state) => {
      state.isLoading = true;
      state.errorMessage = ""
    });

    builder.addCase(searchSalesman.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.isLoading = false;
      state.salemans = insertSTT(data);
      state.total = action.payload.total;
    });
    builder.addCase(searchSalesman.rejected, (state, action: any) => {
      state.isLoading = false;
    });

    builder.addCase(checkSaleman.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(checkSaleman.fulfilled, (state, action) => {

      state.isLoading = false;
      state.isCheck = "correct";
      if(action.payload) {
        state.id = action.payload.id;
        window.location.replace("/")
        return
    }
    });
    builder.addCase(checkSaleman.rejected, (state, action: any) => {
      state.isLoading = false;
      state.isCheck = "incorrect";
    });

    builder.addCase(removeSalesman.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccessDelete = true;
    });

    builder.addCase(removeSalesman.rejected, (state, action: any) => {
      state.isLoading = false;
      state.isSuccessDelete = false;
    });

    builder.addCase(removeSalesman.pending, (state, action) => {
      state.isSuccessDelete = false;
      state.isLoading = false;
    });
  },
});
export default slice.reducer;
// Actions
export const { destroy ,clearProfile} = slice.actions;

export const saleman = createAsyncThunk("saleman", async (params: IPaging) => {
  const result = await salemans(params);
  return result.data;
});

export const detailSalesman = createAsyncThunk(
  "detailSalesman",
  async (id: string) => {
    const result = await detail(id);
    return result.data;
  }
);

export const getProfile = createAsyncThunk("getProfile", async (id: string) => {
  const result = await detail(id);
  return result.data;
});

export const createSaleman = createAsyncThunk(
  "createSaleman",
  async (data: ReqCreateSaleMan) => {
    const result = await postSaleman(data);
    return result.data;
  }
);

export const updateSaleman = createAsyncThunk(
  "updateSaleman",
  async (data: ReqCreateSaleMan) => {
    const result = await update(data);
    return result.data;
  }
);

export const checkSaleman = createAsyncThunk("checkSaleman", async () => {
  const result = await verifySaleman();
  return result.data;
});

export const searchSalesman = createAsyncThunk(
  "searchSalesman",
  async (data:any) => {
    const result = await search(data);
    return result.data;
  }
);

export const removeSalesman = createAsyncThunk(
  "removeSalesman",
  async (id: string) => {
    const result = await deleteSaleman(id);
    return result.data;
  }
);
