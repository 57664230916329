/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import HomePage from './views/home/screen/HomePage';
import { TheLayout } from './../src/components/layout';
import PageNotFound from './views/page404/PageNotFound';
import SalemanPage from './views/saleman/screen/SalemanPage';
import VoucherPage from './views/voucher/screen/VoucherPage';
import { OrderPage } from './views/order';
import { useApp } from './middleware/state/app/hook';
import PrivateRouter from './PrivateRouter';
import { REDIRECT_URL } from './constant/host';
import Oauth2 from './views/home/screen/Oauth2';
import PolicyPage from './views/policy/policy';

export default function App() {
  const { isAccessRole } = useApp();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const accessToken = urlParams.get('accessToken') as string;
  const decodedToken = accessToken || localStorage.getItem('token');

  if (!decodedToken) {
    window.location.href = `https://www.idp.fidt.vn/oauth?redirect=${REDIRECT_URL}&appId=fidtapp`;
    return null;
  }

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRouter>
                <TheLayout />
              </PrivateRouter>
            }
          >
            <Route path="/" element={<Navigate to="/policy" replace />} />
            <Route path="/policy" element={<PolicyPage/>}/>
            <Route path="voucher" element={<VoucherPage />} />
            <Route path="home" element={<HomePage />} />
            {isAccessRole && <Route path="saleman" element={<SalemanPage />} />}
            <Route path="order" element={<OrderPage />} />
          </Route>
          <Route path="oauth2" element={<Oauth2 />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </>
  );
}
