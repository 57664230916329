/* eslint-disable no-mixed-operators */
import { ROLE } from "./../../../constant";
import { RootState } from "middleware/stores";
import { useDispatch, useSelector } from "react-redux";
import {setRole,saveToken } from "./index";
import { clearProfile } from "../saleman";

const SALESMAN_ROLE_CONSTRAINTS_LIST = ['CS', 'CTV Sales', 'Dashboard Admin']

const useApp = () => {
  const dispatch = useDispatch() as any;
  const onSetRole = (payload: any) => {
    const roles = payload.data?.roles;
    if (process.env.NODE_ENV === 'development') {
      const REACT_APP_TEST_ROLE = process.env.REACT_APP_TEST_ROLE ?? "";
      const testRoles = REACT_APP_TEST_ROLE
        .split(',')
        .filter(role => {
          return SALESMAN_ROLE_CONSTRAINTS_LIST.includes(role)
        });
      if (payload.data) {
        payload.data.roles = testRoles;
        console.log('SUCCESS SET TEST ROLE: ' + testRoles.join(" + "));
      }
    }
    dispatch(setRole(payload));
  };
  const onSetToken = (token: string) => {
    dispatch(saveToken(token));
  };

  const onLogout = () => {
    window.location.replace("https://www.idp.fidt.vn/")
    localStorage.removeItem("token")
    dispatch(setRole([]));
    dispatch(clearProfile())
  };

  const { role, username,token,message} = useSelector((state: RootState) => state.persistedReducer.app);

  const isAccessRole :boolean = (!role?.includes(ROLE.CS) && role?.includes(ROLE.CTV_SALES) || role.length === 0) ? false : true

  return {onLogout,onSetToken, onSetRole,isAccessRole, role,username ,token,message};
};




export { useApp };
