import Moment from 'moment-timezone';

export enum DateFormat {
    YYYY_MM_DD = 'YYYY-MM-DD',
    YYYY_MM_DD_T_HH_MM_SS_SSS_Z = 'YYYY-MM-DDTHH:mm:ss.SSSZ',
    YYYY_MM_DD_T_HH_MM_SS_SSS_UTC = 'YYYY-MM-DDTHH:mm:ss.SSS+00:00',
    DD_MMM_YYYY_hh_mm_A = 'DD MMM YYYY hh:mm A',
    DD_MMM_YYYY_HH_mm = 'DD MMM YYYY HH:mm',
    DD_MMM_YYYY = 'DD MMM YYYY',
    HH_MM = 'HH:mm',
    YYYY = 'YYYY',
    MM = 'MM',
    DD = 'DD'
}

export const DateTagRegExp = /^(@Today|@Tomorrow|@Yesterday)([+-])?([0-9]*)?(day|days|month|months|year|years)?$/;

export type TimeUnit = Moment.DurationInputArg2;

export class DateUtils {
  

    public static toMilliseconds(input: string, format: DateFormat): number {
        return Moment(Moment(input).format(format)).toDate().getTime();
    }

    public static isValidDate(input: string | Date, format?: DateFormat): boolean {
        return Moment(input, format).isValid();
    }

  
    public static startOf(input: string | Date, unitOfTime: Moment.unitOfTime.StartOf): Date {
        return Moment(input).startOf(unitOfTime).toDate();
    }

    public static endOf(input: string | Date, unitOfTime: Moment.unitOfTime.StartOf): Date {
        return Moment(input).endOf(unitOfTime).toDate();
    }

    public static formatDate(time :string): string {
        return Moment(new Date(time))
        .utcOffset(420)
        .format("DD/MM/YYYY");
    }
}
