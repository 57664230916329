import React from 'react';

type Props = {
    style?: any
    icon: any,
    className?: any
}

const FIcon = (props: Props) => {
    const { style, icon , className} = props
    return <img src={icon} style={{...style}} className={className} alt=""/>

}

export default FIcon;