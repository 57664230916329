export class StringUtils {
  
    public static round(value: number, precision = 2): number {
        return Math.floor(value * Math.pow(10, precision)) / Math.pow(10, precision);
    }

    public static formatPrice(value: number): string {
         return (value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')).concat(" VNĐ")
    }

    public static lengthString(value: string): boolean {
        return value.length > 12
    }

    public static encodeURI(input: string): string {
        return encodeURIComponent(input);
    }


    public static parseJwt(token: string): any {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
    }
    
}
