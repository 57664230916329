/* eslint-disable react-hooks/exhaustive-deps */
import { Layout, Avatar, Space, Row, Col, Typography, Button } from 'antd';
import React, { memo, useCallback, useEffect, useState } from 'react';
import TheMenu from './TheMenu';
import { UserOutlined } from '@ant-design/icons';
import { FButton, FModal, Title } from './../element';
import { useSaleman } from '../../middleware/state/saleman/hooks';
import { DateUtils } from '../../utils/date.utils';
import { useApp } from '../../middleware/state/app/hook';
import ModalSalemanUpdate from './../../views/saleman/screen/ModalSalemanUpdate';
import { useNotify } from './../../hooks/useNotify';
import { SALESMAN } from '../../constant';
import ModalContactCS from './ModalContactCS';
import ModalContactCSSuccess from './ModalContactCSSuccess';

const { Sider } = Layout;
const styleText = { color: '#D6180C', fontSize: 16, fontWeight: '300' };

const TheSideBar: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    onGetProfile,
    id,
    profile,
    isSuccessCreate,
    onUpdateSaleman,
    onDestroy,
  } = useSaleman();
  const { showNotify, contextHolder } = useNotify();
  const [isCancel, setIsCancel] = useState(false);

  const [action, setAction] = useState({
    isUpdate: false,
    isCancel: false,
  });
  const { onLogout } = useApp();
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (id && id.length !== 0 && token) {
      onGetProfile(id);
    }
  }, [token, id]);

  useEffect(() => {
    if (isSuccessCreate && isCancel) {
      setIsModalOpen(false);
      onGetProfile(id);
      onDestroy();
    }
  }, [isSuccessCreate]);

  const renderModal = useCallback(() => {
    return (
      action.isUpdate && (
        <ModalSalemanUpdate
          isModalOpen={action.isUpdate}
          isMe={true}
          handleCancel={(idSale: any) => {
            setAction({ ...action, isUpdate: false });
            idSale && onGetProfile(idSale);
          }}
          id={id}
        />
      )
    );
  }, [action.isUpdate]);

  const [isContactCSFormShowing, setIsContactCSFormShowing] = useState(false);
  const [isModalSuccessShowing, setIsModalSuccessShowing] = useState(false);

  return (
    <Sider>
      <>
        {contextHolder}
        <div className="logo">
          <Space
            direction="vertical"
            onClick={() => setAction({ ...action, isUpdate: true })}
          >
            <Avatar size={64} icon={<UserOutlined />} />
            <Title text={profile?.wixUserName} style={{ color: 'white' }} />
          </Space>
        </div>
        <div className='principal-document-container'>
          <a className='principal-document-container-link' href="https://fidt.vn/tai-lieu-ctv/" target="_blank">Saleskit bán hàng</a>
        </div>
        <div className="divider" />
        <TheMenu />
        <div className='cs-contact-container'>
          <FButton
            style={{
              borderRadius: '5% / 20%',
              marginTop: '2rem'
            }}
            text='Liên hệ CSKH'
            onClick={() => window.location.href='https://zalo.me/2717936463962266110'}
          />
        </div>
        <div className="contract">
          <div className="info p-1" style={{ display: 'none' }}>
            <Title
              text="Thông tin hợp đồng"
              className="text-white fs-18 center"
            />
            <Space
              direction="vertical"
              style={{ display: 'flex' }}
              size="small"
            >
              {/* <Row className="p-0">
              <Col span={13}> <Title text="Mức hoa hồng:" className="text-white fs-14" /></Col>
              <Col span={11}>
                <Title text={commissionRate} className="text-white fs-14 f-500" />
              </Col>
            </Row> */}
              <Row>
                <Col span={13}>
                  <Title text="Giảm giá:" className="text-white fs-14" />
                </Col>
                <Col span={11}>
                  <Title
                    text={`Tối đa ${profile?.commissionRate}`}
                    className="text-white fs-14 f-500"
                  />
                </Col>
              </Row>
              <Row>
                <Col span={13}>
                  <Title text="Ngày ký:" className="text-white fs-14" />
                </Col>
                <Col span={11}>
                  <Title
                    text={DateUtils.formatDate(profile?.createdAt)}
                    className="text-white fs-14 f-500"
                  />
                </Col>
              </Row>
              <Row>
                <Col span={13}>
                  <Title text="Hiệu lực từ:" className="text-white fs-14" />
                </Col>
                <Col span={11}>
                  <Title
                    text={DateUtils.formatDate(profile?.cooperationStartAt)}
                    className="text-white fs-14 f-500"
                  />
                </Col>
              </Row>
              <Row>
                <Col span={13}>
                  <Title text="Hiệu lực đến:" className="text-white fs-14" />
                </Col>
                <Col span={11}>
                  <Title
                    text={DateUtils.formatDate(profile?.cooperationEndAt)}
                    className="text-white fs-14 f-500"
                  />
                </Col>
              </Row>
              <Row justify="center" align="middle">
                <Col span={18}>
                  <FButton
                    danger
                    text="Dừng hợp đồng"
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                  />
                </Col>
              </Row>
              <Row justify="center" align="middle">
                <Col
                  span={24}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Typography.Text
                    underline
                    style={{ textAlign: 'center', color: 'white' }}
                  >
                    Chi tiết hợp đồng
                  </Typography.Text>
                </Col>
              </Row>
            </Space>
          </div>

          <Row justify="center" align="middle" className="mt-1">
            <Col
              span={24}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Button type="text" onClick={onLogout}>
                <Typography.Text
                  underline
                  style={{ textAlign: 'center', color: 'white' }}
                >
                  Đăng xuất
                </Typography.Text>
              </Button>
            </Col>
          </Row>

          <FModal
            isModalOpen={isModalOpen}
            handleCancel={() => {
              setIsModalOpen(false);
            }}
            handleOk={() => {
              const req = {
                ...profile,
                status: SALESMAN.SALESMAN_STOP_COOPERATION,
              } as any;
              setIsCancel(true);
              onUpdateSaleman(req);
            }}
          >
            <Space
              direction="vertical"
              style={{ display: 'flex' }}
              size="small"
            >
              <Title
                style={{ ...styleText, fontWeight: '600' }}
                text="Cảnh báo"
              />
              <Title
                style={styleText}
                text="Bạn chắc chắn muốn yêu cầu dừng hợp đồng cộng tác viên"
              />
            </Space>
          </FModal>
          {renderModal()}

          <ModalContactCS
            isModalOpen={isContactCSFormShowing}
            handleOk={() => {
              setIsContactCSFormShowing(false);
              setIsModalSuccessShowing(true);
            }}
            handleCancel={() => setIsContactCSFormShowing(false)}
          />
          <ModalContactCSSuccess
            isModalOpen={isModalSuccessShowing}
            handleOk={() => setIsModalSuccessShowing(false)}
            handleCancel={() => setIsModalSuccessShowing(false)}
          />
        </div>
      </>
    </Sider>
  );
};

export default memo(TheSideBar);
