import axios from 'axios';

const refWindow = window as any;
const ENV = refWindow.ENV ?? {};

const ADMIN_URL = process.env.REACT_APP_ADMIN_URL || "https://admin.fidt.vn";

const DIRECTUS_PRODUCTS_URL = process.env.REACT_APP_DIRECTUS_PRODUCTS_URL ?? ENV?.REACT_APP_DIRECTUS_PRODUCTS_URL
const DIRECTUS_PRODUCT_CATEGORIES_URL = process.env.REACT_APP_DIRECTUS_PRODUCT_CATEGORIES_URL ?? ENV?.REACT_APP_DIRECTUS_PRODUCT_CATEGORIES_URL
const DIRECTUS_ACCESS_TOKEN = process.env.REACT_APP_DIRECTUS_ACCESS_TOKEN ?? ENV?.REACT_APP_DIRECTUS_ACCESS_TOKEN

interface ISubscription {
  id: number;
  price: number;
  listing_product_price: number;
  duration: number;
}

interface IProductItem {
  _id: string | null,
  category: number,
  id: number,
  name: string,
  price: number,
  listing_product_price: number;
  subscriptions: ISubscription[]
}

const getAllProducts = async(): Promise<IProductItem[]> => {
  const resp = await axios.get(`${DIRECTUS_PRODUCTS_URL}`, {
    params: {
      fields: '*,subscriptions.*',
      access_token: DIRECTUS_ACCESS_TOKEN
    }
  });
  const data = (resp.data?.data || []) as IProductItem[];
  const products: IProductItem[] = [];
  for (const product of data) {
    if ((product?.subscriptions?.length ?? 0) > 0) {
      for (const sub of product.subscriptions) {
        products.push({
          id: sub?.id,
          _id: '',
          category: product?.category,
          name: product?.name + (sub?.duration > 0 || sub?.duration < -1 ? ' ' + Math.abs(sub?.duration) + (sub?.duration > 0 ? ' tháng' : ' ngày') : ''),
          price: sub?.price,
          listing_product_price: sub?.listing_product_price,
          subscriptions: [],
        })
      }
    }
  }
  return products;
}

export {
  type IProductItem,
  getAllProducts
}
