/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { memo, useEffect, useState } from 'react';
import { FButton } from '../element';
import { Form, DatePicker, InputNumber, Modal, Row, Col, Space, Select, Input } from 'antd';
import moment from 'moment';
import { useNotify } from '../../hooks/useNotify';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useForm } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import { useSaleman } from '../../middleware/state/saleman/hooks';
import { postContactInfoToCompany } from '../../webhook';

const { Option } = Select;

dayjs.extend(customParseFormat);

const CONTACT_REASONS = [
  {
    id: 1,
    value: 1,
    label: '-- Bạn cần hỗ trợ điều gì ---'
  },
  {
    id: 2,
    value: 2,
    label: 'Tạo voucher mới'
  },
  {
    id: 3,
    value: 3,
    label: 'Chỉnh sửa voucher cũ'
  },
  {
    id: 4,
    value: 4,
    label: 'Vấn đề đơn hàng'
  },
  {
    id: 5,
    value: 5,
    label: 'Hướng dẫn rõ hơn về chính sách'
  },
  {
    id: 6,
    value: 6,
    label: 'Vấn đề khác'
  }
]


type Props = {
  handleOk?: () => void;
  handleCancel?: () => void;
  isModalOpen: boolean;
};

function ModalContactCS(props: Props) {
  const [form] = useForm();
  const [selectedResonId, setSelectedReasonId] = useState(1);
  const { profile } = useSaleman();

  useEffect(() => {
    form.setFieldValue('reason', 1);
    form.setFieldValue('detail', '');
  }, [props])

  const sendMessageToDiscord = () => {
    const reasonId = form.getFieldValue('reason');
    const detail = form.getFieldValue('detail') ?? "" as string;
    let discordHook = "https://discord.com/api/webhooks/1116228921587933256/ndWCnDPFaPuCFboz2JsUZpjbAx5ZBSy7oGOLbyTwTW8kbSe2_mxBD-2Qwrs7KGG9iyTm";
    if (process.env.NODE_ENV === 'production') {
      discordHook = "https://discord.com/api/webhooks/1116228921587933256/ndWCnDPFaPuCFboz2JsUZpjbAx5ZBSy7oGOLbyTwTW8kbSe2_mxBD-2Qwrs7KGG9iyTm";
    }
    postContactInfoToCompany(
      profile?.accountName,
      profile?.userEmail,
      profile?.userPhone || profile?.zaloPhone,
      CONTACT_REASONS.filter(u => u.id == reasonId)?.[0]?.label,
      detail
    ).then(() => {
      props?.handleOk?.();
    })
  }

  return (
    <Modal
      open={props.isModalOpen ?? false}
      title="Liên hệ CS"
      footer={null}
      style={{ borderRadius: 10 }}
      width={420}
      onOk={props.handleOk}
      onCancel={props.handleCancel}
      maskClosable={true}
    >
      <>
        <Form layout="vertical" form={form}>
          <Row align="middle" justify="center">
            <Col span={24}>
              <Form.Item
                name="reason"
                style={{ marginBottom: '1.5rem' }}
              >
                <Select
                  placeholder="Bạn muốn hỗ trợ gì?"
                  onSelect={(e: any) => setSelectedReasonId(e)}
                >
                  {CONTACT_REASONS.map(
                    (item, idx) =>
                      item.id !== 0 && (
                        <Option key={idx} value={item.value}>
                          {item.label}
                        </Option>
                      ),
                  )}
                </Select>
              </Form.Item>
              <Form.Item
                name="detail"
                style={{ marginBottom: '1.5rem' }}
              >
                <TextArea
                  rows={3}
                  placeholder='Mô tả cụ thể (nếu có)'
                />
              </Form.Item>
            </Col>
          </Row>

          <div className="text-center" style={{ marginBottom: '1rem' }}>
            <FButton
              text="Xác nhận"
              htmlType="submit"
              disabled={selectedResonId == 1}
              onClick={sendMessageToDiscord}
              style={{
                width: '100%'
              }}
            />
          </div>
        </Form>
      </>
    </Modal>
  );
}

export default ModalContactCS;
