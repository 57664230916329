import {  Layout } from 'antd';
import React from 'react';
import { images } from '../../assets/images';

const { Header, Content } = Layout;

type Props = {
  message ?: string
}

const PageNotFound = (props:Props) => {
  const {message = " Không tìm thấy trang"} = props
  return  <Layout>
  <Header style={{ width: '100%' }}>
    <img
      src={images.logo}
      alt=""
      className="logo-header"
    />
  </Header>
  <Layout>
    <Layout style={{ minHeight: '100vh' }}>
      <Content style={{ margin: '0 16px' ,justifyContent:"center",alignItems:"center",display:"flex"}}>
      <h1 style={{fontSize:"10vh",color:"#032657", textAlign:"center"}}>
      {message}
      </h1>
      </Content>
    </Layout>
  </Layout>
</Layout>
}

export default PageNotFound;