import { Layout } from 'antd';
import React from 'react';
import { images } from '../../assets';
import TheSideBar from './TheSideBar';
import { useOutlet } from "react-router"

const { Header, Content } = Layout;



const TheLayout: React.FC = () => {
  const outlet = useOutlet();
  return (
    <Layout>
      <Header style={{ width: '100%' }}>
        <img
          src={images.logo}
          alt=""
          className="logo-header"
        />
      </Header>
      <Layout>
        <TheSideBar />
        <Layout style={{ minHeight: '100vh' }}>
          <Content style={{ margin: 20 }}>
            {outlet}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default TheLayout;