import axios from "axios";

const windowENV = typeof window == undefined ? {} : (window as any).ENV as any;
const API_URL = process.env.REACT_APP_API || windowENV?.REACT_APP_API;

const instance = axios.create({
  baseURL: API_URL
})

instance.interceptors.request.use(
  async function (config: any) {
    const token =  localStorage.getItem("token");
    if (token && config) {
      config.headers = {
        "authorization": token,
      };
    }
    return config;
  },

  function (error) {
    return Promise.reject(error);
  }
);

export {
  instance
}
