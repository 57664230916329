import React from "react";
import { Pagination,  Table} from "antd";
import type { ColumnsType } from "antd/es/table";

type Props = {
  style?: any;
  columns: ColumnsType<any>;
  dataSource: any;
  className?: any;
  loading?: boolean;
  total ?: number
  current : number
  onChangePage: (e:number) => void

};

const FTable = (props: Props) => {
  const { style, dataSource, columns, loading,total,current,onChangePage, ...rest } = props;

  return (
    <div className="bg-white p-2 d-flex jusitfy-content-end flex-column">
      <Table
        rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        {...rest}
        scroll={{ x: 1300 }}
        pagination={false}
      />
      <Pagination
        defaultCurrent={1}
        current={current}
        total={total}
        className="mt-2 align-self-end"
        onChange={(e)=>onChangePage(e)}
      />
    </div>
  );
};

export default FTable;
