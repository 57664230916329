import instance from "./interceptor";


export const GET = (endpoint : string) => {
  return instance.get(endpoint);
};

export const POST = (endpoint : string, body : any) => {
  return instance.post(endpoint, body);
};

export const DELETE = (endpoint : string, body ?: any) => {
  return instance.delete(endpoint, body);
};

export const PUT = (endpoint : string, body?: any) => {
  return instance.put(endpoint, body);
};
