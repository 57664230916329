import { createSlice } from "@reduxjs/toolkit";
import { APP } from "./type";

const AppState: APP = {
  type: "orders",
  role: [""],
  username: "",
  token : "",
  message: ""
};

const slice = createSlice({
  name: "App",
  initialState: AppState,
  reducers: {
    changeBaseUrl: (state, action) => {
      state.type = action.payload;
    },
    setRole: (state, action) => {
      if(action.payload) {
        state.username = action.payload.data?.email || ""
        state.role = action.payload.data?.roles || []
        return
      }
    },
    saveToken : (state,action) => {
      state.token = action.payload.tọken
    },
    setMessageError : (state,action) => {

      state.message = action.payload
    }
  },
});
export default slice.reducer;
// Actions
export const { changeBaseUrl, setRole ,saveToken,setMessageError} = slice.actions;
