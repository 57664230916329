

export class QueryUtils {

  public static query(params: any) {
    for (let propName in params) {
      if (params[propName] === null || params[propName] === undefined) {
        delete params[propName];
      }
    }

    return "?".concat(
      Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&")
    );
  }
 
}
