import { Modal, Space } from "antd";
import React from "react";
import { FButton } from "./../element";

type Props = {
  handleCancel?: () => void;
  handleOk?: () => void;
  isModalOpen: boolean;
  children: React.ReactNode;
};
const FModal = (props: Props) => {
  const { handleOk, handleCancel, isModalOpen, children } = props;

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleCancel}
      footer={
        <Space>
          <FButton text="Đóng" onClick={handleCancel} type="default" />
          <FButton text="Xác nhận" onClick={handleOk} danger />
        </Space>
      }
      bodyStyle={{}}
    >
      {children}
    </Modal>
  );
};

export default FModal;
