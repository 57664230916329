/* eslint-disable react-hooks/exhaustive-deps */
import { FButton, FModal, FSearch, FTable } from '../../../components/element';
import React, { useState, useEffect } from 'react';
import { Checkbox, Col, Modal, Space } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { DateUtils, StringUtils } from '../../../utils';
import ModalActionVoucher from './ModalVoucher';
import { useVoucher } from '../../../middleware/state/voucher/hooks';
import { IPaging } from 'middleware/state/voucher/type';
import { useNotify } from '../../../hooks/useNotify';
import { useSaleman } from '../../../middleware/state/saleman/hooks';
import { useApp } from '../../../middleware/state/app/hook';
import { getAllProducts, IProductItem } from '../../../api/admin';
import huongDanQuanTrongImg from '../../../assets/images/hdqt.png';
import { ROLE } from '../../../constant';

// const VOUCHER_STATUS = [
//   {
//     id: 0,
//     value: 0,
//     label: 'Tất cả',
//   },
//   {
//     id: 1,
//     value: 1,
//     label: 'Đang hoạt động',
//   },
//   {
//     id: 2,
//     value: 2,
//     label: 'Đã xoá',
//   },
// ];

function VoucherPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [params, setParams] = useState<IPaging>({
    limit: 10,
    offset: 1,
  });
  const [isModalVoucherPolicyOpening, setIsModalVoucherPolicyOpening] = useState(false);
  const [action, setAction] = useState('create');
  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [search, setSearch] = useState() as any;
  const { id } = useSaleman();
  console.log('🚀 ~ file: VoucherPage.tsx:48 ~ VoucherPage ~ id:', id);
  const { isAccessRole, role } = useApp();
  const [idVoucher, setIdVoucher] = useState('');
  const {
    onGetList,
    onRemove,
    onSearchVoucher,
    vouchers,
    isLoading,
    total,
    isSuccessDelete,
  } = useVoucher();
  const { showNotify } = useNotify();

  const columns = [
    {
      title: '#',
      dataIndex: 'stt',
      key: 'stt',
      width: 50,
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'expiredAt',
      key: 'expiredAt',
      render: (expiredAt: string) => (
        <span>{DateUtils.formatDate(expiredAt)}</span>
      ),
    },
    {
      title: 'Mã',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Giảm',
      dataIndex: 'discountPercentage',
      key: 'discountPercentage',
      render: (discount: any) => {
        return (
          <span>{StringUtils.formatPrice(discount)}</span>
        )
      }
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Còn lại',
      dataIndex: '',
      key: '',
      render: (_: any, row: any) => (
        <span>{row.quantity - row.numberOfUsed}</span>
      ),
    },
    {
      title: 'Sản phẩm',
      dataIndex: 'productName',
      key: 'productName'
    },
    {
      title: 'Giá sản phẩm hiện tại',
      dataIndex: 'productPrice',
      key: 'productPrice'
    },
    {
      title: '',
      key: 'action',
      // fixed: 'right' as any,
      width: 100,
      render: (_: any, row: any) => (
        <>
          {!row.del && role?.includes(ROLE.CS) && (
            <Space>
              <EditOutlined
                onClick={() => {
                  setAction('edit');
                  setIsModalOpen(true);
                  setIdVoucher(row.id);
                }}
              />
              {/* <DeleteOutlined
                style={{ color: 'red' }}
                onClick={() => {
                  setIsModalConfirm(true);
                  setIdVoucher(row.id);
                }}
              /> */}
            </Space>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    if (!isAccessRole) {
      onGetList({
        ...params,
        salesmanId: id,
      });
      return;
    }
    onGetList(params);
  }, [params]);

  useEffect(() => {
    if (isSuccessDelete) {
      showNotify('success', `Xoá voucher thành công`);
      setIsModalConfirm(false);
      setIdVoucher('');
      onGetList(params);
    }
  }, [isSuccessDelete]);

  const handleFilter = (req: any) => {
    setSearch(req);
    onSearchVoucher(req);
  };

  const handleCancelFilter = () => {
    setSearch(null);
    onGetList(params);
  };

  const [fixedVouchers, setFixedVouchers] = useState<any[]>([]);
  const [products, setProducts] = useState<IProductItem[]>([]);

  useEffect(() => {
    getAllProducts().then(items => {
      setProducts(items);
      console.log(items);
    })
  }, []);

  useEffect(() => {
    const newFixedVouchers = [];

    for (const voucher of vouchers) {
      const productId = voucher?.productId;

      const temp = {
        ...voucher,
        productName: products.find(product => product.id == productId)?.name || "-",
        productPrice: StringUtils.formatPrice(products.find(product => product.id == productId)?.price || 0)
      };

      newFixedVouchers.push(temp);
    }

    setFixedVouchers(newFixedVouchers);
  }, [vouchers, products]);

  return (
    <div>
      <div className="d-flex justify-content-between mb-2">
        <Col span={6}>
          <Space>
            <FSearch
              textholder="Mã voucher"
              onClick={(e) => {
                if (e) {
                  handleFilter({ codes: [e], limit: 10, offset: 1 });
                  return;
                }
                handleCancelFilter();
              }}
              style={{}}
            />
            {role?.includes(ROLE.CS) && <FButton
              text='Hướng dẫn quan trọng'
              style={{
                borderRadius: '2.5%/15%'
              }}
              onClick={() => setIsModalVoucherPolicyOpening(true)}
            />}
          </Space>
        </Col>
        <Col
          offset={12}
          style={{ justifyContent: 'flex-end' }}
        >
          {role?.includes(ROLE.CS) && <FButton
            text="Thêm mới"
            onClick={() => {
              setIsModalOpen(true);
              setAction('create');
            }}
            size="large"
          />}

        </Col>
      </div>
      {isModalOpen && (
        <ModalActionVoucher
          isModalOpen={isModalOpen}
          handleCancel={() => {
            setIsModalOpen(false);
            setIdVoucher('');
            handleFilter(search);
          }}
          action={action}
          id={idVoucher}
        />
      )}
      {isModalConfirm && (
        <FModal
          isModalOpen={isModalConfirm}
          handleOk={() => {
            onRemove(idVoucher);
          }}
          handleCancel={() => setIsModalConfirm(false)}
        >
          <h6 style={{ color: '#D6190B', fontWeight: 'bold' }}>Cảnh báo</h6>
          <span style={{ color: '#D6190B', marginTop: 20 }}>
            Bạn muốn xoá voucher này ?
          </span>
        </FModal>
      )}
      <Modal
        open={isModalVoucherPolicyOpening}
        footer={null}
        title={'Hướng dẫn quan trọng'}
        width={1231}
        onCancel={() => setIsModalVoucherPolicyOpening(false)}
        onOk={() => setIsModalVoucherPolicyOpening(false)}
      >
        <img
          src={huongDanQuanTrongImg}
          style={{
            width: '100%'
          }}
        />
      </Modal>
      <FTable
        columns={columns}
        dataSource={fixedVouchers}
        className="mt-2"
        loading={isLoading}
        total={total}
        current={params.offset}
        onChangePage={(e: number) => {
          if (search && Object.keys(search).length !== 0) {
            onSearchVoucher({ ...search, offset: e });
            return;
          }
          setParams({ ...params, offset: e });
        }}
      />
    </div>
  );
}

export default VoucherPage;
