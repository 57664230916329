import { DatePicker, Space, Row, Col, Divider } from "antd";
import "dayjs/locale/vi";
import locale from "antd/es/date-picker/locale/vi_VN";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "moment";
import styled from "styled-components";
import { StringUtils } from "../../../utils/string";
import { FSelect } from "../../../components/element";
dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

const RowFirst = styled.div<{ backgroundColor: string }>`
  background-color: ${(props) => props.backgroundColor};
  width: 100%;
  padding: 20px;
  box-shadow: 0px 4px 4px rgba(0, 49, 87, 0.25);
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;
const Title = styled.h4`
  color: #003157;
  margin-top: 20px;
`;
const Text = styled.span<{ fontWeight?: number }>`
  color: #003157;
  font-size: 14px;
  font-weight: ${(props) => props.fontWeight};
`;

function HomePage() {
  const disabledDate = (current: any) => {
    return current && current > moment().endOf("day");
  };

  return null

  // return (
  //   <div className="bg-white p-2 pb-4">
  //     <Row>
  //       <Col span={11}>
  //         <Space>
  //           <Text>Thời gian tạo đơn</Text>
  //           <RangePicker locale={locale} disabledDate={disabledDate} />
  //         </Space>
  //         <Title>THỐNG KÊ TỔNG QUAN</Title>
  //         <Divider className="p-0 m-0" />
  //         <RowFirst backgroundColor={"#FFE9DB"} >
  //           <Text>Tổng đơn</Text>
  //           <Text fontWeight={700}>100</Text>
  //         </RowFirst>
  //         <RowFirst backgroundColor={"white"}>
  //           <Text>Doanh thu trên giá niêm yết</Text>
  //           <Text>{StringUtils.formatPrice(21321100000)}</Text>
  //         </RowFirst>
  //         <RowFirst backgroundColor={"#FFE9DB"}>
  //           <Text>Hoa hồng tính trên doanh thu</Text>
  //           <Text>{StringUtils.formatPrice(103210000)}</Text>
  //         </RowFirst>
  //         <RowFirst backgroundColor={"white"}>
  //           <Text>Tiền giảm giá khách hàng</Text>
  //           <Text>{StringUtils.formatPrice(100321000)}</Text>
  //         </RowFirst>
  //         <RowFirst backgroundColor={"#FFE9DB"}>
  //           <Text>Hoa hồng thực nhận</Text>
  //           <Text fontWeight={700}>{StringUtils.formatPrice(103210000)}</Text>
  //         </RowFirst>
  //       </Col>
  //       <Col span={11} offset={2}>
  //         <Space>
  //           <Text>Sales</Text>
  //           <FSelect textholder="Vui lòng chọn Sales" options={[]} onChangeValue={(e)=>console.log(e)}/>
  //         </Space>
  //         <Title>THỐNG KÊ THEO SẢN PHẨM</Title>
  //         <Divider className="p-0 m-0" />

  //         <RowFirst backgroundColor={"#FFE9DB"}>
  //           <div style={{width:"40%"}}/>
  //             <Text fontWeight={700} style={{width:"20%"}}>Số đơn</Text>
  //             <Text fontWeight={700} style={{width:"40%"}}>Doanh thu trên giá niêm yết</Text>
  //         </RowFirst>
  //         <RowFirst backgroundColor={"white"}>
  //             <Text style={{width:"40%"}}>CCI Gói trải nghiệm</Text>
  //             <Text  style={{width:"20%"}}>50</Text>
  //             <Text style={{width:"40%"}}>{StringUtils.formatPrice(1000000000)}</Text>
  //         </RowFirst>
  //         <RowFirst backgroundColor={"#FFE9DB"}>
  //             <Text  style={{width:"40%"}}>CCI Gói trải nghiệm</Text>
  //             <Text  style={{width:"20%"}}>20</Text>
  //             <Text  style={{width:"40%"}}>{StringUtils.formatPrice(1000000000)}</Text>
  //         </RowFirst>
  //         <RowFirst backgroundColor={"white"}>
  //           <Text  style={{width:"40%"}}>Gói nâng cao</Text>
  //             <Text  style={{width:"20%"}}>20</Text>
  //             <Text  style={{width:"40%"}}>{StringUtils.formatPrice(1000000000)}</Text>
  //         </RowFirst>
  //         <RowFirst backgroundColor={"#FFE9DB"}>
  //           <Text  style={{width:"40%"}}>Khoá học X</Text>
  //             <Text  style={{width:"20%"}}>0</Text>
  //             <Text  style={{width:"40%"}}>{StringUtils.formatPrice(0)}</Text>
  //         </RowFirst>
  //       </Col>
  //     </Row>
  //   </div>
  // );
}

export default HomePage;
