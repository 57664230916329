import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import voucher from "../state/voucher/index";
import saleman from "../state/saleman/index";
import order from "../state/order/index";
import app from "../state/app/index";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage: storage,
};
const rootStorage = combineReducers({
  app,
  saleman,
})
const persistedReducer = persistReducer(persistConfig, rootStorage)


const reducer = combineReducers({
  voucher,
  order,
  persistedReducer
  // here we will be adding reducers
});
export type RootState = ReturnType<typeof store.getState>;

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  })
});
export default store;
export const persistor = persistStore(store)
