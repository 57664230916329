import { RootState } from "middleware/stores";
import { useDispatch ,useSelector} from "react-redux";
import { createOrder, destroy, detailOrder, searchOrder, updateOrder, order, getPackage } from "./index";
import { IPaging} from "./type";


const useOrder = ()  => {
  const dispatch = useDispatch() as any
  const onGetList = (params:IPaging ) => {
    dispatch(order(params))
  }

  const onGetDetail = (id:string ) => {
    dispatch(detailOrder(id))
  }

  const onSearchOrder = (data :any) => {
    dispatch(searchOrder(data))
  }
  const onCreateOrder = (data :any) => {
    dispatch(createOrder(data))
  }

  const onUpdateOrder = (data :any) => {
    dispatch(updateOrder(data))
  }

  const onGetPackage = () => {
    dispatch(getPackage())
  }

  const onDestroy = () => {
    dispatch(destroy())
  }

  const {isLoading,isCheck,orders,isSuccess,detail,total,isSuccessDelete,isSuccessCreate,package_order} = useSelector((state: RootState) => state.order);
  
  return  {onGetList,onDestroy,onGetDetail,onSearchOrder,onCreateOrder,onUpdateOrder,onGetPackage,total,orders,detail,package_order,isCheck,isLoading,isSuccess,isSuccessCreate,isSuccessDelete}
};


export {useOrder};
