import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IPaging, Order } from "./type";
import { search, detail, update, create, orders, packages } from "./api";

const OrderState: Order = {
  orders: [],
  isLoading: false,
  errorMessage: "",
  isCheck: false,
  total: 0,
  isSuccess: false,
  isSuccessCreate: false,
  isSuccessDelete: false,
  detail: {
    customerPhone: '',
    customerEmail: "",
    servicePackage: "",
    servicePackageAmountTotal: 0,
    status: "",
    saleId: "",
    voucherId: "",
    createdDate: "",
  },
  package_order: [],
  loadingButton: false
}

const insertSTT = (data: any) => {
  return data.map((item: any, index: number) => {
    return {
      ...item,
      stt: index + 1
    }
  })
}

const slice = createSlice({
  name: "Order",
  initialState: OrderState,
  reducers: {
    destroy: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isSuccessCreate = false;
      state.isSuccessDelete = false;
    },

  },
  extraReducers: (builder) => {
    builder.addCase(order.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(order.fulfilled, (state, action) => {
      state.isLoading = false;
      const { data } = action.payload
      state.orders = insertSTT(data)
      state.total = action.payload.total
    });
    builder.addCase(order.rejected, (state, action: any) => {
      state.isLoading = false;
    });

    builder.addCase(detailOrder.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(detailOrder.fulfilled, (state, action) => {
      state.isLoading = false;
      state.detail = action.payload
    });
    builder.addCase(detailOrder.rejected, (state, action: any) => {
      state.isLoading = false;
    });


    builder.addCase(createOrder.pending, (state) => {
      state.isSuccessCreate = false

    });

    builder.addCase(createOrder.fulfilled, (state, action) => {
      state.isSuccessCreate = true
    });

    builder.addCase(createOrder.rejected, (state, action: any) => {
      state.isSuccessCreate = false;
    });


    builder.addCase(updateOrder.pending, (state) => {
      state.isLoading = true;
      state.isSuccessCreate = false

    });

    builder.addCase(updateOrder.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccessCreate = true
    });

    builder.addCase(updateOrder.rejected, (state, action: any) => {
      state.isLoading = false;
      state.isSuccessCreate = false;
    });

    builder.addCase(searchOrder.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(searchOrder.fulfilled, (state, action) => {
      const { data, total } = action.payload
      state.isLoading = false;
      state.orders = insertSTT(data)
      state.total = total

    });

    builder.addCase(searchOrder.rejected, (state, action: any) => {
      state.isLoading = false;
    });


    builder.addCase(getPackage.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getPackage.fulfilled, (state, action) => {
      state.package_order = action.payload.payload.payload;
      state.isLoading = false;

    });

    builder.addCase(getPackage.rejected, (state, action: any) => {
      state.isLoading = false;
    });

  },
});
export default slice.reducer;
// Actions
export const { destroy } = slice.actions;

export const order = createAsyncThunk("Order", async (params: IPaging) => {
  const result = await orders(params);
  return result.data;
});

export const detailOrder = createAsyncThunk("detailOrder", async (id: string) => {
  const result = await detail(id);
  return result.data;
});

export const createOrder = createAsyncThunk("createOrder", async (data: any) => {
  const result = await create(data);
  return result.data;
});

export const updateOrder = createAsyncThunk("updateOrder", async (data: any) => {
  const result = await update(data);
  return result.data;
});

export const searchOrder = createAsyncThunk("searchOrder", async (data: any) => {
  const result = await search(data);
  return result.data;
});

export const getPackage = createAsyncThunk("getPackage", async () => {
  const result = await packages();
  return result.data;
});