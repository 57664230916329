/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { memo, useEffect, useState } from 'react';
import { FButton } from '../element';
import { Form, DatePicker, InputNumber, Modal, Row, Col, Space, Select, Input } from 'antd';
import moment from 'moment';
import { useNotify } from '../../hooks/useNotify';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import tickCircle from '../../assets/icons/tick-circle.png';

const { Option } = Select;

dayjs.extend(customParseFormat);

type Props = {
  handleOk?: () => void;
  handleCancel?: () => void;
  isModalOpen: boolean;
};

function ModalContactCSSuccess(props: Props) {
  return (
    <Modal
      open={props.isModalOpen ?? false}
      title="Liên hệ CS"
      footer={null}
      style={{ borderRadius: 10 }}
      width={420}
      onOk={props.handleOk}
      onCancel={props.handleCancel}
      maskClosable={true}
    >
      <>
        <Form layout="vertical">
          <Row align="middle" justify="center">
            <Col span={24}>
              <div className='contact-cs-modal-success-img-container'>
                <img
                  src={tickCircle}
                  className="contact-cs-modal-success-img"
                />
              </div>
              <p className="contact-cs-modal-success-text">
                CS sẽ xử lý yêu cầu của bạn ngay
              </p>
            </Col>
          </Row>

          <div className="text-center">
            <FButton
              text="Đã hiểu"
              htmlType="submit"
              onClick={props.handleOk}
              style={{
                width: '100%'
              }}
            />
          </div>
        </Form>
      </>
    </Modal>
  );
}

export default ModalContactCSSuccess;
