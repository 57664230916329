/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from 'react';
import { FButton, FModal } from '../../../components/element';
import {
  Form,
  Input,
  DatePicker,
  InputNumber,
  Modal,
  Row,
  Col,
  Space,
  Select,
  Checkbox,
} from 'antd';
import moment from 'moment';
import { useSaleman } from '../../../middleware/state/saleman/hooks';
import { ReqCreateSaleMan } from '../../../middleware/state/saleman/type';
import { useNotify } from '../../../hooks/useNotify';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useApp } from '../../../middleware/state/app/hook';
import { SALESMAN, SALESMAN_STATUS } from './../../../constant';
const { Option } = Select;

dayjs.extend(customParseFormat);

type Props = {
  handleCancel: (id?: string) => void;
  handleOk?: () => void;
  isModalOpen: boolean;
  id: string;
  isMe?: boolean;
};

function ModalSalemanUpdate(props: Props) {
  const { handleCancel, isModalOpen, id, isMe = false } = props;
  const [statusSaleman, setStatusSaleman] = useState('');
  const [form] = Form.useForm();
  const {
    onCreateSaleman,
    onDestroy,
    onUpdateSaleman,
    onGetDetail,
    detail,
    isLoading,
    isSuccessCreate,
    errorMessage
  } = useSaleman();
  const { role: currentUserRoles } = useApp();

  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const { showNotify, contextHolder } = useNotify();
  const [action, setAction] = useState('');

  const formatDate = (e: string) => {
    return moment(e).utcOffset(420).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
  };
  const {
    accountBankBranch,
    accountBankName,
    accountName,
    accountNumber,
    address,
    commissionRate,
    cooperationStartAt,
    cooperationEndAt,
    facebookLink,
    personalId,
    personalLicenseAt,
    personalLicensePlace,
    userEmail,
    userPhone,
    zaloPhone,
    wixUserName,
    status,
    isWorkingAtFIDT,
    presenterPhoneNumber
  } = detail as any;
  const onFinish = (values: ReqCreateSaleMan) => {
    const req = {
      ...values,
      wixUserId: id,
      cooperationStartAt: formatDate(values.cooperationStartAt),
      cooperationEndAt: formatDate(values.cooperationEndAt),
      status: values.status,
      personalLicensePlace: values.personalLicensePlace
        ? values.personalLicensePlace
        : '',
      personalLicenseAt: values.personalLicenseAt
        ? formatDate(values.personalLicenseAt)
        : '',
      zaloPhone: values.userPhone
    };

    id.length === 0 ? onCreateSaleman(req) : onUpdateSaleman(req);
  };

  const messageNoti = () => {
    if (action === 'cancel') {
      return 'Huỷ hợp tác thành công';
    } else {
      switch (statusSaleman) {
        case 'SALESMAN_STOP_COOPERATION':
          return 'Yêu cầu dừng hợp tác thành công';
        case 'SALESMAN_PAUSE_COOPERATION':
          return 'Tạm dừng hợp tác thành công';
        case 'SALESMAN_TERMINATE_CONTRACT':
          return 'Chấm dứt hợp đồng thành công';
        default:
          return 'Chỉnh sửa saleman thành công';
      }
    }
  };

  useEffect(() => {
    if (isSuccessCreate) {
      showNotify(
        'success',
        messageNoti()
      );
      handleCancel(id);
      onDestroy();
      form.resetFields();
    }
  }, [isSuccessCreate]);


  useEffect(() => {
    if (errorMessage) {
      showNotify('error', errorMessage);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (id.length !== 0) {
      onGetDetail(id);
      return;
    }
  }, [id]);

  useEffect(() => {
    if (detail && Object.keys(detail).length !== 0)
      form.setFieldsValue({
        accountBankBranch: accountBankBranch,
        accountBankName: accountBankName,
        accountName: accountName,
        accountNumber: accountNumber,
        address: address,
        commissionRate: commissionRate,
        cooperationEndAt: moment(cooperationEndAt),
        cooperationStartAt: moment(cooperationStartAt),
        personalLicenseAt: moment(personalLicenseAt),
        facebookLink: facebookLink,
        personalId: personalId,
        personalLicensePlace: personalLicensePlace || '',
        userEmail: userEmail,
        userPhone: userPhone,
        wixUserName: wixUserName,
        zaloPhone: zaloPhone,
        status: status,
        isWorkingAtFIDT: isWorkingAtFIDT,
        presenterPhoneNumber: presenterPhoneNumber
      });
      console.log(detail);
  }, [detail]);

  const closeModal = () => {
    form.resetFields();
    handleCancel();
  };

  return (
    <Modal
      open={isModalOpen}
      width={'60%'}
      title={'Chỉnh sửa Salesman'}
      footer={null}
      style={{ borderRadius: 10 }}
      onCancel={closeModal}
      maskClosable={false}
    >
      <>
        {contextHolder}
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row>
            <Col span={11}>
              <Row>
                <Col span={24}>
                  <span>Email</span>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="userEmail"
                        rules={[
                          {
                            required: true,
                            message: 'Email không được bỏ trống',
                          },
                          {
                            type: 'email',
                            message: 'Email không hợp lệ',
                          },
                        ]}
                      >
                        <Input
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} style={{
                  display: 'none'
                }}>
                  <Row>
                    <Col span={24}>
                      <span>Trạng thái</span>
                    </Col>
                    <Col span={24}>
                      <Row>
                        <Col span={24}>
                          <Form.Item name="status">
                            <Select
                              style={{
                                width: '100%'
                              }}
                              disabled={!(currentUserRoles?.includes("CS") || currentUserRoles?.includes("Dashboard Admin"))}
                              placeholder="Trạng thái"
                              onSelect={(e) => setStatusSaleman(e)}
                            >
                              {SALESMAN_STATUS.map(
                                (item, idx) =>
                                  item.id !== 0 && (
                                    <Option key={idx} value={item.value}>
                                      {item.label}
                                    </Option>
                                  ),
                              )}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={11} offset={2}>
              <Row>
                <Col span={24}>
                  <span>&nbsp;</span>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="isWorkingAtFIDT"
                        valuePropName="checked"
                      >
                        <Checkbox
                        >
                          Đang làm việc lại FIDT
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <h5>Thông tin cá nhân</h5>
            </Col>
            <Col span={11}>
              <Row>
                <Col span={24}>
                  <span>Tên người dùng *</span>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="wixUserName"
                    rules={[
                      {
                        required: true,
                        message: 'Tên người dùng không được để trống',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <span>Địa chỉ</span>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="address"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <span>CCCD</span>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="personalId"
                  >
                    <Input
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <span>Ngày cấp </span>
                </Col>
                <Col span={24}>
                  <Form.Item name="personalLicenseAt" initialValue={null}>
                    <DatePicker
                      format="YYYY-MM-DD"
                      placeholder="Chọn ngày"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={11} offset={2}>
              <Row>
                <Col span={24}>
                  <span>Số điện thoại *</span>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="userPhone"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const regexPhoneNumber = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;
                          if (value.match(regexPhoneNumber)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(value.length === 0 ? 'Số điện thoại không được để trống' : 'Số điện thoại không hợp lệ'));

                        },
                      }),
                    ]}

                  >
                    <Input maxLength={10} />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <span>Liên hệ khác</span>
                </Col>
                <Col span={24}>
                  <Form.Item name="facebookLink">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <span>Nơi cấp</span>
                </Col>
                <Col span={24}>
                  <Form.Item name="personalLicensePlace">
                    <Input
                      
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <h5>Thông tin tài chính</h5>
            </Col>
            <Col span={11}>
              <Row>
                <Col span={24}>
                  <span>Số tài khoản</span>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="accountNumber"
                    tooltip={{
                      title: 'Tooltip with customize icon',
                      icon: <span>*</span>,
                    }}
                  >
                    <Input
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <span>Chủ tài khoản</span>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="accountName"
                  >
                    <Input
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={11} offset={2}>
              <Row>
                <Col span={24}>
                  <span>Ngân hàng</span>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="accountBankName"
                  >
                    <Input
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <span>Chi nhánh</span>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="accountBankBranch"
                  >
                    <Input
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <h5>Thông tin hợp tác</h5>
            </Col>
            <Col span={11}>
              <Row>
                <Col span={24}>
                  <span>Ngày bắt đầu</span>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="cooperationStartAt"
                    rules={[
                      { required: true, message: 'Vui lòng chọn ngày bắt đầu' },
                    ]}
                  >
                    <DatePicker
                      onOk={(e) => console.log(e)}
                      placeholder="Chọn ngày"
                      // disabled={isMe}
                      // onChange={(e)=>{
                      //   if(e) {
                      //     setIsChooseDate(true)
                      //     return 
                      //   }
                      //   setIsChooseDate(false)
                      //   form.resetFields(["cooperationEndAt"]);
                      // }}

                      disabled={!(currentUserRoles?.includes("CS") || currentUserRoles?.includes("Dashboard Admin"))}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ display: 'none' }}>
                <Col span={24}>
                  <span>Mức hoa hồng (%) *</span>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="commissionRate"
                    rules={[
                      { required: true, message: 'Vui lòng nhập mức hoa hồng' },
                    ]}
                  >
                    <InputNumber
                      placeholder="%"
                      defaultValue={30}
                      min={0}
                      max={30}
                      disabled={!(currentUserRoles?.includes("CS") || currentUserRoles?.includes("Dashboard Admin"))}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={11} offset={2}>
              <Row>
                <Col span={24}>
                  <span>Ngày kết thúc *</span>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="cooperationEndAt"
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng chọn ngày kết thúc',
                      },
                    ]}
                  >
                    <DatePicker
                      onChange={(e) => console.log('change', e)}
                      onOk={(e) => console.log(e)}
                      placeholder="Chọn ngày"
                      disabled={!(currentUserRoles?.includes("CS") || currentUserRoles?.includes("Dashboard Admin"))}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <span>Người giới thiệu (SĐT)</span>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="presenterPhoneNumber"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const regexPhoneNumber = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;
                          if (!value || value.match(regexPhoneNumber)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Số điện thoại không hợp lệ'));
                        },
                      }),
                    ]}
                    
                  >
                    <Input maxLength={10}/>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="d-flex justify-content-end">
            <Space>
              {id.length !== 0 && !isMe && (
                <FButton
                  text={'Huỷ hợp tác'}
                  loading={isLoading}
                  type="text"
                  className="bg-secondary text-light"
                  onClick={() => {
                    setIsModalConfirm(true);
                    setAction('cancel');
                  }}
                />
              )}
              <FButton
                text={'Chỉnh sửa'}
                htmlType="submit"
                loading={isLoading}
              />
            </Space>
          </div>
        </Form>
        {isModalConfirm && (
          <FModal
            isModalOpen={isModalConfirm}
            handleOk={() => {
              const req = {
                wixUserId: id,
                accountBankBranch: accountBankBranch,
                accountBankName: accountBankName,
                accountName: accountName,
                accountNumber: accountNumber,
                address: address,
                commissionRate: commissionRate,
                cooperationEndAt: cooperationEndAt,
                cooperationStartAt: cooperationStartAt,
                personalLicenseAt: personalLicenseAt,
                facebookLink: facebookLink,
                personalId: personalId,
                personalLicensePlace: personalLicensePlace || '',
                userEmail: userEmail,
                userPhone: userPhone,
                wixUserName: wixUserName,
                zaloPhone: userPhone,
                status: SALESMAN.SALESMAN_PAUSE_COOPERATION,
              } as any;
              onUpdateSaleman(req);
            }}
            handleCancel={() => setIsModalConfirm(false)}
          >
            <h6 style={{ color: '#D6190B', fontWeight: 'bold' }}>Cảnh báo</h6>
            <span style={{ color: '#D6190B', marginTop: 20 }}>
              Bạn muốn huỷ hợp tác với salesman này?
            </span>
          </FModal>
        )}
      </>
    </Modal>
  );
}

export default memo(ModalSalemanUpdate);
