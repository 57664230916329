/* eslint-disable react-hooks/exhaustive-deps */
import { Spin } from 'antd';
import { Layout } from 'antd';
import { useSaleman } from './../../../middleware/state/saleman/hooks';
import React, { useEffect ,useState} from 'react';
import { images } from '../../../assets/images';
import { StringUtils } from './../../../utils/string';
import { useApp } from './../../../middleware/state/app/hook';
import ModalVerify from './../../../views/saleman/screen/ModalVerify';

const { Header, Content } = Layout;

const Oauth2 = () => {

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const accessToken = urlParams.get('accessToken') as string;
  const decodedToken = accessToken || localStorage.getItem('token');
  const { onSetRole } = useApp();
  const {isCheck,onDestroy, onVerify,id } = useSaleman();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (accessToken) {
      localStorage.setItem('token', accessToken);
      onVerify();
      return;
    }
  }, [accessToken]);

  useEffect(() => {
    if (decodedToken) {
      const data = StringUtils.parseJwt(decodedToken);
      onSetRole(data);
      return;
    }
  }, []);

  useEffect(() => {
    if (isCheck === 'incorrect') {
      setIsModalOpen(true);
      onDestroy();
    }
  }, [isCheck,id]);



  return (
    <Layout>
      <Header style={{ width: '100%' }}>
        <img src={images.logo} alt="" className="logo-header" />
      </Header>
      <Layout>
        <Layout style={{ minHeight: '100vh' }}>
          <Content
            style={{
              margin: '0 16px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Spin spinning size="large" />
          </Content>
        </Layout>
      </Layout>
      {isModalOpen && (
        <ModalVerify
          isModalOpen={isModalOpen}
          handleCancel={() => setIsModalOpen(false)}
        />
      )}
    </Layout>
  );
};

export default Oauth2;
