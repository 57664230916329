/* eslint-disable react-hooks/exhaustive-deps */
import { FButton, FSearch, FSelect, FTable } from '../../../components/element';
import React, { useState, useEffect } from 'react';
import { Row, Col, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { DateUtils } from '../../../utils';
import { useSaleman } from '../../../middleware/state/saleman/hooks';
import { convertStatusSaleman, SALESMAN_STATUS } from '../../../constant';
import { IPaging } from 'middleware/state/saleman/type';
import ModalSalemanCreate from './ModalSalemanCreate';
import ModalSalemanUpdate from './ModalSalemanUpdate';


const default_params = {
  limit: 10,
  offset: 1,
};

function Saleman() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [params, setParams] = useState<IPaging>({
    limit: 10,
    offset: 1,
  });
  const [action, setAction] = useState('create');
  const [search, setSearch] = useState() as any;
  const [offsetSearch, setOffSetSearch] = useState(1);
  const [id, setId] = useState('');
  const { onGetListSalesman, onSearchSaleman, salemans, isLoading, total } =
    useSaleman();
    const [paramsSearch, setParamsSearch] = useState({
      limit: 10,
      offset: 1,
    }) as any;
  
  const columns = [
    {
      title: '#',
      dataIndex: 'stt',
      key: 'stt',
      width: 50,
    },
    {
      title: 'Ngày thao tác',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string) => (
        <span>{DateUtils.formatDate(createdAt)}</span>
      ),
    },
    {
      title: 'Tài khoản',
      dataIndex: 'userEmail',
      key: 'userEmail',
      render: (userEmail: string) => (
        <span style={{ color: '#003157', fontWeight: 'bold' }}>
          {userEmail}
        </span>
      ),
    },
    {
      title: 'Tên người dùng',
      dataIndex: 'wixUserName',
      key: 'wixUserName',
      render: (wixUserName: string) => <span>{wixUserName}</span>,
    },
    {
      title: 'Mức hoa hồng',
      dataIndex: 'commissionRate',
      key: 'commissionRate',
      render: (commissionRate: number) => <span>{commissionRate}%</span>,
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'userPhone',
      key: 'userPhone',
    },
    {
      title: 'Ngày hợp tác',
      dataIndex: 'cooperationStartAt',
      key: 'cooperationStartAt',
      render: (cooperationStartAt: string) => (
        <span>{DateUtils.formatDate(cooperationStartAt)}</span>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => <span>{convertStatusSaleman(status)}</span>,
    },
    {
      title: '',
      key: 'action',
      fixed: 'right' as any,
      width: 100,
      render: (_: any, row: any) => (
        <>
        <EditOutlined
          onClick={() => {
            setAction('edit');
            setIsModalOpen(true);
            setId(row.id);
          }}
        />
        </>
        
      ),
    },
  ];

  useEffect(() => {
    onGetListSalesman(params);
  }, [params]);

  const handleFilter = (req: any) => {
    setSearch(req);
    setParamsSearch(req)
    onSearchSaleman(req);
  };

  const handleCancelModal = () => {
    setIsModalOpen(false);
    setId('');
    handleFilter(paramsSearch)
  }

  const renderModalAction = () => {
    if (isModalOpen) {
      return action === 'edit' ? (
        <ModalSalemanUpdate
          isModalOpen={isModalOpen}
          handleCancel={handleCancelModal}
          id={id}
        />
      ) : (
        <ModalSalemanCreate
          isModalOpen={isModalOpen}
          handleCancel={handleCancelModal}
        />
      );
    }
  };

  const handleCancelFilter = (key?: string,isResetall?:any) => {
    if (
      isResetall
    ) {
      onGetListSalesman(default_params);
      setParams(default_params);
      setParamsSearch(null);
      setSearch(null)
      return;
    }

    switch (key) {
      case 'q':
        handleFilter({ ...paramsSearch, q: null });
        break;
      case 'statuses':
        handleFilter({ ...paramsSearch, statuses: null });
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <Row align="middle" justify="space-between" className="mb-2">
        <Col span={8}>
          <Space>
            <FSearch
              textholder="Email / Số điện thoại"
              onClick={(e) => {
                if (e) {
                  handleFilter({ q: e, limit: 10, offset: 1 });
                  return;
                }
                handleCancelFilter("q",true);
              }}
            />
            <FSelect
              textholder="Trạng thái"
              options={SALESMAN_STATUS}
              onChangeValue={(e) => {
                if (e) {
                  handleFilter({ statuses: [e], limit: 10, offset: 1 });
                  return;
                }
                handleCancelFilter("statuses",true);
              }}
            />
          </Space>
        </Col>
        <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <FButton
            text="Thêm mới"
            onClick={() => {
              setIsModalOpen(true);
              setAction('create');
            }}
            size="large"
          />
        </Col>
      </Row>

      {renderModalAction()}

      <FTable
        columns={columns}
        dataSource={salemans}
        className="mt-2"
        loading={isLoading}
        total={total}
        current={
          search && Object.keys(search).length !== 0
            ? offsetSearch
            : params.offset
        }
        onChangePage={(e: number) => {
          if (search && Object.keys(search).length !== 0) {
            onSearchSaleman({ ...search, offset: e });
            setOffSetSearch(e);
            return;
          }
          setParams({ ...params, offset: e });
        }}
      />
    </div>
  );
}

export default Saleman;
