import React from "react";
import {  Select } from "antd";
import type { SizeType } from "antd/es/config-provider/SizeContext";


type Props = {
  style?: any;
  size?: SizeType;
  textholder?: string;
  onChangeValue: (e : string) => void;
  options : any
};

const FSelect = (props: Props) => {
  const { textholder,options, onChangeValue} = props;
 
  const onChange = (value: string) => {
    onChangeValue(value)
  };

  const onSearch = (value: string) => {
  };
  return (
    <Select
      className="w-100"
      placeholder={textholder}
      optionFilterProp="children"
      onChange={onChange}
      onSearch={onSearch}
      filterOption={(input, option:any) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      options={options}
    />
  );
};

export default FSelect;
