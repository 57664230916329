
import { QueryUtils } from "./../../../utils/index";
import { DELETE, GET, POST, PUT  } from "../../../api/method";
export const vouchers = (params ?: any) => {
  return GET(`voucher/voucher-queries/vouchers/get-all${QueryUtils.query(params)}`);
};

export const detail = (id : string) => {
  return GET(`voucher/voucher-queries/vouchers/${id}`);
};


export const create = (data : any) => {
  return POST(`voucher/voucher-commands/vouchers/add`,data);
};


export const search = (data : any) => {
  return POST(`voucher/voucher-queries/vouchers/search `,data);
};


export const update = (data : any) => {
  return PUT(`voucher/voucher-commands/vouchers/${data.id}/edit`,data);
};


export const deleteVoucher = (id : string) => {
  return DELETE(`voucher/voucher-commands/vouchers/${id}/remove`);
};


