import React from 'react';
import { Button, Modal } from 'antd';
import { useApp } from '../../../middleware/state/app/hook';

type Props = {
  isModalOpen: boolean;
  handleCancel?: () => void;
};

const ModalVerify = (props: Props) => {
  const { isModalOpen, handleCancel } = props;
  const { onLogout } = useApp();

  return (
    <>
      <Modal
        closable={false}
        bodyStyle={{ borderRadius: 32 }}
        cancelButtonProps={{ style: { display: 'none' } }}
        footer={null}
        open={isModalOpen}
        onCancel={handleCancel}
        maskClosable={false}
      >
        <div className="d-flex flex-row justify-content-between">
          <p style={{ color: '#F58A07', fontWeight: '700' }}>Thông báo</p>
          <Button type="text" onClick={onLogout}>
            <p style={{ color: '#F58A07', fontWeight: '500' }}>X</p>
          </Button>
        </div>
        <span>
          Bạn chưa có quyền truy cập vào Tính năng này. Vui lòng liên hệ hotline{' '}
          <span style={{ color: '#F58A07', fontWeight: '700' }}>
            028.888.935.79
          </span>{' '}
          để được hỗ trợ.
        </span>
      </Modal>
    </>
  );
};

export default ModalVerify;
