/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { memo, useEffect, useState } from 'react';
import { FButton } from '../element';
import { Form, DatePicker, InputNumber, Modal, Row, Col, Space } from 'antd';
import moment from 'moment';
import { useNotify } from '../../hooks/useNotify';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

dayjs.extend(customParseFormat);

type Props = {
  handleOk?: () => void;
  isModalOpen: boolean;
};

function ModalAnalysisInfo(props: Props) {
  return (
    <Modal
      open={props.isModalOpen ?? false}
      title="Thông báo"
      footer={null}
      style={{ borderRadius: 10 }}
      onOk={props.handleOk}
      onCancel={props.handleOk}
      maskClosable={true}
    >
      <>
        <Form layout="vertical">
          <Row align="middle" justify="center">
            <Col span={24}>
              Thống kê sẽ được đối soát và gửi chi tiết vào 5 ngày kể từ ngày khai giảng khóa học.
            </Col>
          </Row>

          <div className="d-flex justify-content-end text-center">
            <FButton
              text="Đã hiểu"
              htmlType="submit"
              onClick={props.handleOk}
            />
          </div>
        </Form>
      </>
    </Modal>
  );
}

export default ModalAnalysisInfo;
