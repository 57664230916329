import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ReqCreateVoucher, Voucher } from "./type";
import { vouchers, search, detail, update, create, deleteVoucher } from "./api";
import { getAllProducts } from "../../../api/admin";

const VoucherState: Voucher = {
  vouchers: [],
  isLoading: false,
  errorMessage: "",
  isCheck: false,
  total: 0,
  isSuccessCreate: false,
  isSuccessDelete: false,
  isSuccess: false,
  detail: {
    salesmanId: "",
    discountPercentage: 0,
    quantity: 0,
    expiredAt: "",
    code: "",
    numberOfUsed: 0,
    productId: 0
  },
};

const insertSTT = (data: any) => {
  const newArr = data.sort((a: any, b: any) => a.del - b.del).map((item: any, index: number) => {
      return {
        ...item,
        stt: index + 1,
      };
    })

  return newArr;
};

const slice = createSlice({
  name: "Voucher",
  initialState: VoucherState,
  reducers: {
    destroy: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isSuccessCreate = false;
      state.isSuccessDelete = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(voucher.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(voucher.fulfilled, (state, action) => {
      state.isLoading = false;
      const { data } = action.payload;
      state.vouchers = insertSTT(data);
      state.total = action.payload.total;
    });
    builder.addCase(voucher.rejected, (state, action: any) => {
      state.isLoading = false;
    });

    builder.addCase(detailVoucher.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(detailVoucher.fulfilled, (state, action) => {
      state.isLoading = false;
      state.detail = action.payload;
    });
    builder.addCase(detailVoucher.rejected, (state, action: any) => {
      state.isLoading = false;
    });

    builder.addCase(createVoucher.pending, (state) => {
      state.isLoading = true;
      state.isSuccessCreate = false;
    });

    builder.addCase(createVoucher.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccessCreate = true;
    });

    builder.addCase(createVoucher.rejected, (state, action: any) => {
      state.isLoading = false;
      state.isSuccessCreate = false;
    });

    builder.addCase(updateVoucher.pending, (state) => {
      state.isLoading = true;
      state.isSuccessCreate = false;
    });

    builder.addCase(updateVoucher.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccessCreate = true;
    });

    builder.addCase(updateVoucher.rejected, (state, action: any) => {
      state.isLoading = false;
      state.isSuccessCreate = false;
    });

    builder.addCase(searchVoucher.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(searchVoucher.fulfilled, (state, action) => {
      state.isLoading = false
      const { data } = action.payload;
      state.isLoading = false;
      state.vouchers = insertSTT(data);
      state.total = action.payload.total;
    });

    builder.addCase(searchVoucher.rejected, (state, action: any) => {
      state.isLoading = false;
    });

    builder.addCase(removeVoucher.pending, (state) => {
      state.isLoading = true;
      state.isSuccessDelete = false;
    });

    builder.addCase(removeVoucher.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccessDelete = true;
    });

    builder.addCase(removeVoucher.rejected, (state, action: any) => {
      state.isLoading = false;
      state.isSuccessDelete = false;
    });
  },
});
export default slice.reducer;
// Actions
export const { destroy } = slice.actions;

export const voucher = createAsyncThunk("voucher", async (params?: any) => {
  const result = await vouchers(params);
  return result.data;
});

export const detailVoucher = createAsyncThunk(
  "detailVoucher",
  async (id: string) => {
    const result = await detail(id);
    return result.data;
  }
);

export const createVoucher = createAsyncThunk(
  "createVoucher",
  async (data: ReqCreateVoucher) => {
    const result = await create(data) as any;
    if (result?.status === 'ERROR') {
      if (typeof(data.onError) === 'function')
        data.onError(result?.message);
      throw new Error();
    }
    return result.data;
  }
);

export const updateVoucher = createAsyncThunk(
  "updateVoucher",
  async (data: ReqCreateVoucher) => {
    const result = await update(data) as any;
    if (result?.status === 'ERROR') {
      if (typeof(data.onError) === 'function')
        data.onError(result?.message);
      throw new Error();
    }
    return result.data;
  }
);

export const searchVoucher = createAsyncThunk(
  "searchvoucher",
  async (data: any) => {
    const result = await search(data);
    return result.data;
  }
);

export const removeVoucher = createAsyncThunk(
  "removeVoucher",
  async (id: string) => {
    const result = await deleteVoucher(id);
    return result.data;
  }
);
