/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { memo, useEffect, useState } from 'react';
import { FButton } from '../../../components/element';
import { Form, DatePicker, InputNumber, Modal, Row, Col, Space, Select } from 'antd';
import moment from 'moment';
import { useVoucher } from '../../../middleware/state/voucher/hooks';
import { useNotify } from '../../../hooks/useNotify';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import Input from 'antd/lib/input/Input';
import { useModifyVoucherContext } from '../hook/useModifyVoucherContext';

dayjs.extend(customParseFormat);

type Props = {
  handleCancel: () => void;
  handleOk?: () => void;
  isModalOpen: boolean;
  action: string;
  id: string;
};

const default_discount = 5

function ModalVoucher(props: Props) {
  const {
    listSalesmans,
    productCategories,
    products,
    setSelectedProductCategoryId,
    listProductForSelectedCategory,
    selectedProductCategoryId
  } = useModifyVoucherContext();

  const { handleCancel, isModalOpen, action, id } = props;
  const [form] = Form.useForm();
  const {
    onCreateVoucher,
    onDestroy,
    onUpdateVoucher,
    onGetDetail,
    detail,
    isLoading,
    isSuccessCreate,
  } = useVoucher();
  const { showNotify, contextHolder } = useNotify();

  const formatDate = (e: string) => {
    return moment(e).format('YYYY-MM-DDTHH:mm:ss.000Z');
  };
  const {
    discountPercentage,
    quantity,
    expiredAt,
    code: voucherCode
  } = detail as any;
  const onFinish = (values: any) => {
    const req = {
      ...values,
      isSubscription: true,
      id: id.length !== 0 ? id : '',
      expiredAt: formatDate(values.expiredAt),
      onError: (message: string) => {
        showNotify('error', message);
      }
    };

    id.length === 0 ? onCreateVoucher(req) : onUpdateVoucher(req);
  };

  useEffect(() => {
    if (isSuccessCreate) {
      showNotify(
        'success',
        `${action === 'create' ? 'Tạo' : 'Chỉnh sửa'}  voucher thành công`,
      );
      handleCancel();
      onDestroy();
      form.resetFields();
    }
  }, [isSuccessCreate]);

  useEffect(() => {
    form.resetFields();

    if (id.length !== 0) {
      onGetDetail(id);
      return;
    }
  }, [id]);

  useEffect(() => {
    if (id.length !== 0 && detail && Object.keys(detail).length !== 0) {
      const categoryId = products.filter(product => product.id === detail.productId)?.[0]?.categoryId;
      
      form.setFieldsValue({
        expiredAt: moment(expiredAt),
        discountPercentage: discountPercentage,
        quantity: quantity,
        voucherCode: voucherCode,
        salesId: detail.salesmanId,
        productCategoryId: categoryId,
        productId: detail.productId,
      });
      setSelectedProductCategoryId(categoryId)
    }
  }, [detail, products]);

  const closeModal = () => {
    handleCancel();
    form.resetFields();
  };

  const disabledDate = (current: any) => {
    return current && current < moment().endOf('day');
  };

  return (
    <Modal
      open={isModalOpen}
      title={action === 'create' ? 'Tạo Voucher mới' : 'Chỉnh sửa Voucher'}
      footer={null}
      style={{ borderRadius: 10 }}
      onCancel={closeModal}
      maskClosable={true}
    >
      <>
        {contextHolder}
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row align="middle" justify="center">
            <Col span={18}>
              <Row>
                <Col span={24} className="mb-2">
                  <span>Loại hình sản phẩm</span>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="productCategoryId"
                        rules={[
                          {
                            validator: (_, value: string): Promise<void> => {
                              if (value != null && value != '') {
                                return Promise.resolve();
                              }
                              else {
                                return Promise.reject('Loại sản phẩm không được trống')
                              }
                            }
                          }
                        ]}
                      >
                        <Select
                          style={{
                            width: '100%'
                          }}
                          onSelect={(e) => setSelectedProductCategoryId(e as number)}
                          value={selectedProductCategoryId}
                        >
                          {productCategories?.map((productCategory, idx) => {
                            return (
                              <Select.Option key={idx} value={productCategory.id}>
                                {productCategory.name}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col span={18}>
              <Row>
                <Col span={24} className="mb-2">
                  <span>Sản phẩm</span>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="productId"
                        rules={[
                          {
                            validator: (_, value: string): Promise<void> => {
                              if (value != null && value != '') {
                                return Promise.resolve();
                              }
                              else {
                                return Promise.reject('Sản phẩm không được trống')
                              }
                            }
                          }
                        ]}
                      >
                        <Select
                          style={{
                            width: '100%'
                          }}
                        >
                          {listProductForSelectedCategory?.map((product, idx) => {
                            return (
                              <Select.Option key={idx} value={product.id}>
                                {product.name}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col span={18}>
              <Row>
                <Col span={24} className="mb-2">
                  <span>Email</span>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="salesId"
                        rules={[
                          {
                            validator: (_, value: string): Promise<void> => {
                              if (value != null && value != '') {
                                return Promise.resolve();
                              }
                              else {
                                return Promise.reject('Email không được trống')
                              }
                            }
                          }
                        ]}
                      >
                        <Select
                          style={{
                            width: '100%'
                          }}
                        >
                          {listSalesmans?.map((salesman, idx) => {
                            return (
                              <Select.Option key={idx} value={salesman.id}>
                                {salesman.userEmail}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col span={18}>
              <Row>
                <Col span={24} className="mb-2">
                  <span>Mã voucher</span>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="voucherCode"
                        rules={[
                          {
                            validator: (_, value: string): Promise<void> => {
                              if (value != null && value != '') {
                                return Promise.resolve();
                              }
                              else {
                                return Promise.reject('Mã voucher không được trống')
                              }
                            }
                          }
                        ]}
                      >
                        <Input
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col span={18}>
              <Row>
                <Col span={24} className="mb-2">
                  <span>Số lượng</span>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="quantity"
                        rules={[
                          {
                            validator: (_, value: string): Promise<void> => {
                              if (value != null && value != '') {
                                return Promise.resolve();
                              }
                              else {
                                return Promise.reject('Số lượng không được trống')
                              }
                            }
                          }
                        ]}
                      >
                        <InputNumber
                          controls={false}
                          min={1}
                          max={Math.pow(10, 9)}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col span={18}>
              <Row>
                <Col span={24} className="mb-2">
                  <span>Số tiền giảm giá</span>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="discountPercentage"
                        rules={[
                          {
                            validator: (_, value: string): Promise<void> => {
                              if (value != null) {
                                return Promise.resolve();
                              }
                              else {
                                return Promise.reject('Số tiền giảm giá không được trống')
                              }
                            }
                          }
                        ]}
                      >
                        <InputNumber
                          min={0}
                          max={Math.pow(10, 9)}
                          controls={false}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col span={18}>
              <Row>
                <Col span={24} className="mb-2">
                  <span>Ngày hết hạn hiệu lực</span>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="expiredAt"
                    rules={[{ required: true, message: 'Vui lòng chọn ngày' }]}
                  >
                    <DatePicker
                      format="DD-MM-YYYY"
                      style={{ width: '100%' }}
                      disabledDate={disabledDate}
                      placeholder="Chọn ngày"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="d-flex justify-content-end">
            <FButton
              text={id.length === 0 ? 'Tạo mới' : 'Chỉnh sửa'}
              htmlType="submit"
              loading={isLoading}
            />
          </div>
        </Form>
      </>
    </Modal>
  );
}

export default memo(ModalVoucher);
