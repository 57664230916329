import { RootState } from "middleware/stores";
import { useDispatch ,useSelector} from "react-redux";
import { createVoucher, destroy, detailVoucher, removeVoucher, searchVoucher, updateVoucher, voucher } from "./index";


const useVoucher = ()  => {
  const dispatch = useDispatch() as any
  const onGetList = (params?:any) => {
    dispatch(voucher(params))
  }

  const onGetDetail = (id:string ) => {
    dispatch(detailVoucher(id))
  }

  const onSearchVoucher = (data :any) => {
    dispatch(searchVoucher(data))
  }
  const onCreateVoucher = (data :any) => {
    dispatch(createVoucher(data))
  }

  const onUpdateVoucher = (data :any) => {
    dispatch(updateVoucher(data))
  }

  const onRemove= (id :string) => {
    dispatch(removeVoucher(id))
  }

  const onDestroy = () => {
    dispatch(destroy())
  }

  const {isLoading,isCheck,vouchers,isSuccess,detail,total,isSuccessCreate,isSuccessDelete} = useSelector((state: RootState) => state.voucher);
  
  return  {onGetList,onDestroy,onRemove,onGetDetail,onSearchVoucher,onCreateVoucher,onUpdateVoucher,total,vouchers,detail,isCheck,isLoading,isSuccess,isSuccessCreate,isSuccessDelete}
};


export {useVoucher};
