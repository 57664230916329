import axios from "axios"

const refWindow = window as any;
const ENV = refWindow.ENV ?? {};

const TELEGRAM_WEBHOOK_API = process.env.REACT_APP_TELEGRAM_CONTACT_WEBHOOK ?? ENV.REACT_APP_TELEGRAM_CONTACT_WEBHOOK as string;
const TELEGRAM_WEBHOOK_API_SEND_MESSAGE = TELEGRAM_WEBHOOK_API + '/sendMessage';
const TELEGRAM_TARGET_CHAT_ID = parseInt(process.env.REACT_APP_TELEGRAM_CONTACT_CHAT_ID || ENV.REACT_APP_TELEGRAM_CONTACT_CHAT_ID as string);

const DISCORD_WEBHOOK = process.env.REACT_APP_DISCORD_CONTACT_WEBHOOK || ENV.REACT_APP_DISCORD_CONTACT_WEBHOOK as string;

const sendContactInfoToTelegram = async (
  fullName: string,
  email: string,
  phoneNumber: string,
  reason: string,
  detail?: string
) => {
  try {
    await axios.post(TELEGRAM_WEBHOOK_API_SEND_MESSAGE, {
      text: `
Hỗ trợ cộng tác viên:
Họ và tên: ${fullName}
Email: ${email}
SĐT: ${phoneNumber}
Nội dung cần hỗ trợ: ${reason}
Ghi chú thêm: ${detail}      
      `,
      chat_id: TELEGRAM_TARGET_CHAT_ID
    });
  }
  catch (e: any) {
    console.log('Error when send telegram message:', e);
  }
}

const sendContactInfoToDiscord = async (
  fullName: string,
  email: string,
  phoneNumber: string,
  reason: string,
  detail?: string
) => {
  try {
    await axios.post(DISCORD_WEBHOOK, {
      content: `
# Hỗ trợ cộng tác viên:

* Họ và tên: ${fullName}
* Email: ${email}
* SĐT: ${phoneNumber}
* Nội dung cần hỗ trợ: ${reason}
* Ghi chú thêm: ${detail}
`
    });
  }
  catch (e: any) {
    console.log('Error when send discord message:', e);
  }
}

const postContactInfoToCompany = async(
  fullName: string,
  email: string,
  phoneNumber: string,
  reason: string,
  detail?: string
) => {
  await sendContactInfoToDiscord(fullName, email, phoneNumber, reason, detail);
  await sendContactInfoToTelegram(fullName, email, phoneNumber, reason, detail);
}

export {
  sendContactInfoToTelegram,
  sendContactInfoToDiscord,
  postContactInfoToCompany
}
