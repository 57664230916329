import axios from "axios";
import React, { useEffect, useState } from "react";
import { instance } from "../api";

export interface IProductCategory {
  id: number;
  name: string;
}

export interface ISubscription {
  id: number;
  duration: number;
  listing_product_price: number;
  price: number;
}

export interface IProduct {
  id: number;
  categoryId: number;
  name: string;
  price: number;
  subscriptions?: ISubscription[] | undefined,
  is_from_subscription: boolean;
}

export interface ISalesman {
  id: string;
  userEmail: string;
}

const refWindow = window as any;
const ENV = refWindow.ENV ?? {};

const useModifyVoucherContext = () => {
  const [productCategories, setProductCategories] = useState<IProductCategory[]>([]);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [selectedProductCategoryId, setSelectedProductCategoryId] = useState<number|null>(null);
  const [listProductForSelectedCategory, setListProductForSelectedCategory] = useState<IProduct[]>([]);
  const [listSalesmans, setListSalesmans] = useState<ISalesman[]>([]);

  const refreshListProducts = async() => {
    const [categoryResp, productResp] = await Promise.all([
      axios.get(`${process.env.REACT_APP_DIRECTUS_PRODUCT_CATEGORIES_URL ?? ENV?.REACT_APP_DIRECTUS_PRODUCT_CATEGORIES_URL}`, {
        params: {
          'access_token': process.env.REACT_APP_DIRECTUS_ACCESS_TOKEN ?? ENV?.REACT_APP_DIRECTUS_ACCESS_TOKEN,
        }
      }),
      axios.get(`${process.env.REACT_APP_DIRECTUS_PRODUCTS_URL ?? ENV?.REACT_APP_DIRECTUS_PRODUCTS_URL}`, {
        params: {
          'access_token': process.env.REACT_APP_DIRECTUS_ACCESS_TOKEN ?? ENV?.REACT_APP_DIRECTUS_ACCESS_TOKEN,
          'fields': '*,subscriptions.*'
        }
      })
    ]);

    const fixedProductCategories = (categoryResp.data?.data as any[] || []).map(item => {
      return {
        id: item?.id,
        name: item?.name,
      }
    });

    const rawProducts: IProduct[] = (productResp.data?.data as any[] || []).map(item => {
      return {
        id: item?.id,
        name: item?.name,
        price: item?.name,
        categoryId: item?.category,
        subscriptions: item?.subscriptions,
        is_from_subscription: false
      }
    });

    const oldProducts: IProduct[] = [];
    const fixedProducts: IProduct[] = [];

    for (const product of rawProducts) {
      if (product?.subscriptions && (product?.subscriptions?.length || 0) > 0) {
        for (const subscription of product?.subscriptions) {
          fixedProducts.push({
            id: subscription.id,
            price: subscription.price,
            subscriptions: [],
            name: product?.name + ' ' + (subscription?.duration <= 0 ? '' : subscription?.duration + ' tháng'),
            categoryId: product?.categoryId,
            is_from_subscription: true
          })
        }
      }
      else {
        oldProducts?.push(product);
      }
    }

    setProductCategories(value => value = fixedProductCategories);
    setProducts(value => value = fixedProducts);

    setSelectedProductCategoryId(fixedProductCategories?.[0]?.id);
  }

  const refreshListEmails = async() => {
    const [emailResp] = await Promise.all([
      instance.get(`salesman/salesman-queries/salesmans/get-all`,{
        params: {
          limit: Math.pow(10, 9)
        }
      })
    ]);
    
    const fixedSalemans: ISalesman[] = (emailResp.data?.data?.data as any[] || []).map(item => {
      return {
        id: item?.id,
        userEmail: item?.userEmail
      }
    }) || [];
    
    setListSalesmans(fixedSalemans);
  }

  useEffect(() => {
    refreshListProducts();
    refreshListEmails();
  }, []);

  useEffect(() => {
    if (selectedProductCategoryId != null) {
      const filtered = products?.filter(item => item.categoryId == selectedProductCategoryId) || [];
      setListProductForSelectedCategory(value => value = filtered);
    }
  }, [selectedProductCategoryId]);

  return {
    productCategories,
    products, 
    listProductForSelectedCategory,
    selectedProductCategoryId,
    listSalesmans,
    setSelectedProductCategoryId,
  }
}

export {
  useModifyVoucherContext
}
