
import { QueryUtils } from "./../../../utils/index";
import { DELETE, GET, POST, PUT  } from "../../../api/method";
import { IPaging } from "./type";

export const salemans = (params : IPaging) => {
  return GET(`salesman/salesman-queries/salesmans/get-all${QueryUtils.query(params)}&del=false`);
};

export const detail = (id : string) => {
  return GET(`salesman/salesman-queries/salesmans/${id}`);
};


export const postSaleman = (data : any) => {
  return POST(`salesman/salesman-commands/salesmans/add`,data);
};


export const search = (data : any) => {
  return POST(`salesman/salesman-queries/salesmans/search `,data);
};


export const update = (data : any) => {
  return PUT(`salesman/salesman-commands/salesmans/${data.wixUserId}/edit`,data);
};


export const verifySaleman = () => {
  return PUT(`salesman/salesman-commands/salesmans/verify`);
};


export const deleteSaleman = (id : string) => {
  return DELETE(`salesman/salesman-commands/salesmans/${id}/remove`);
};

