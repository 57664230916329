/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from 'react';
import { FButton } from '../../../components/element';
import {
  Form,
  Input,
  DatePicker,
  InputNumber,
  Modal,
  Row,
  Col,
  Space,
  Checkbox,
} from 'antd';
import moment from 'moment';
import { useSaleman } from '../../../middleware/state/saleman/hooks';
import { ReqCreateSaleMan } from '../../../middleware/state/saleman/type';
import { useNotify } from '../../../hooks/useNotify';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

type Props = {
  handleCancel: () => void;
  handleOk?: () => void;
  isModalOpen: boolean;
};

function ModalSalemanCreate(props: Props) {
  const { handleCancel, isModalOpen } = props;
  const [form] = Form.useForm();
  const [isChooseDate, setIsChooseDate] = useState(false)
  const { onCreateSaleman, onDestroy, isLoading, isSuccessCreate, errorMessage } =
    useSaleman();
  const { showNotify, contextHolder } = useNotify();

  const formatDate = (e: string) => {
    return moment(e).utcOffset(420).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
  };

  const onFinish = (values: ReqCreateSaleMan) => {
    const req = {
      ...values,
      wixUserId: 'abc',
      cooperationStartAt: formatDate(values.cooperationStartAt),
      cooperationEndAt: formatDate(values.cooperationEndAt),
      status: 'SALESMAN_ACTIVE',
      personalLicensePlace: values.personalLicensePlace
        ? values.personalLicensePlace
        : '',
      personalLicenseAt: values.personalLicenseAt
        ? formatDate(values.personalLicenseAt)
        : '',
      isWorkingAtFIDT: values.isWorkingAtFIDT ?? false,
      zaloPhone: values.userPhone
    };

    onCreateSaleman(req);
  };

  useEffect(() => {
    if (isSuccessCreate) {
      showNotify('success', `Tạo saleman thành công`);
      handleCancel();
      onDestroy();
      form.resetFields();
    }
  }, [isSuccessCreate]);

  useEffect(() => {
    form.setFieldValue('cooperationStartAt', moment(new Date()));
    form.setFieldValue('cooperationEndAt', moment(new Date()).add(365, 'days'));
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showNotify('error', errorMessage);
    }
  }, [errorMessage]);

  const closeModal = () => {
    form.resetFields();
    handleCancel();
  };

  const disabledDate = (current: any) => {
    return current && current < moment().endOf('day');
  };

  return (
    <Modal
      open={isModalOpen}
      width={'60%'}
      title={'Tạo Salesman mới'}
      footer={null}
      style={{ borderRadius: 10 }}
      onCancel={closeModal}
      maskClosable={false}
    >
      <>
        {contextHolder}
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row>
            <Col span={11}>
              <Row>
                <Col span={24}>
                  <span>Email</span>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="userEmail"
                        rules={[
                          {
                            required: true,
                            message: 'Email không được bỏ trống',
                          },
                          {
                            type: 'email',
                            message: 'Email không hợp lệ',
                          },
                        ]}
                      >
                        <Input style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={11} offset={2}>
              <Row>
                <Col span={24}>
                  <span>&nbsp;</span>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="isWorkingAtFIDT"
                        valuePropName="checked"
                      >
                        <Checkbox>
                          Đang làm việc lại FIDT
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <h5>Thông tin cá nhân</h5>
            </Col>
            <Col span={11}>
              <Row>
                <Col span={24}>
                  <span>Tên người dùng *</span>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="wixUserName"
                    rules={[
                      {
                        required: true,
                        message: 'Tên người dùng không được để trống',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <span>Địa chỉ</span>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="address"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <span>CCCD</span>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="personalId"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <span>Ngày cấp </span>
                </Col>
                <Col span={24}>
                  <Form.Item name="personalLicenseAt" initialValue={null}>
                    <DatePicker format="YYYY-MM-DD" placeholder="Chọn ngày" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={11} offset={2}>
              <Row>
                <Col span={24}>
                  <span>Số điện thoại *</span>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="userPhone"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const regexPhoneNumber = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;
                          if (value.match(regexPhoneNumber)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(value.length === 0 ? 'Số điện thoại không được để trống' : 'Số điện thoại không hợp lệ'));
                        },
                      }),
                    ]}

                  >
                    <Input maxLength={10} />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <span>Liên hệ khác</span>
                </Col>
                <Col span={24}>
                  <Form.Item name="facebookLink">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <span>Nơi cấp</span>
                </Col>
                <Col span={24}>
                  <Form.Item name="personalLicensePlace">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <h5>Thông tin tài chính</h5>
            </Col>
            <Col span={11}>
              <Row>
                <Col span={24}>
                  <span>Số tài khoản</span>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="accountNumber"
                    tooltip={{
                      title: 'Tooltip with customize icon',
                      icon: <span>*</span>,
                    }}
                  >
                    <Input style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <span>Chủ tài khoản</span>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="accountName"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={11} offset={2}>
              <Row>
                <Col span={24}>
                  <span>Ngân hàng</span>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="accountBankName"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <span>Chi nhánh</span>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="accountBankBranch"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <h5>Thông tin hợp tác</h5>
            </Col>
            <Col span={11}>
              <Row>
                <Col span={24}>
                  <span>Ngày bắt đầu</span>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="cooperationStartAt"
                  >
                    <DatePicker
                      onOk={(e) => console.log(e)}
                      placeholder="Chọn ngày"
                      onSelect={(e) => setIsChooseDate(true)}
                      onChange={(e) => {
                        if (e) {
                          setIsChooseDate(true)
                          return
                        }
                        setIsChooseDate(false)
                        form.resetFields(["cooperationEndAt"]);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ display: 'none' }}>
                <Col span={24}>
                  <span>Mức hoa hồng (%)</span>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="commissionRate"
                    initialValue={30}
                  >
                    <InputNumber
                      placeholder="%"
                      max={30}
                      min={0}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={11} offset={2}>
              <Row>
                <Col span={24}>
                  <span>Ngày kết thúc *</span>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="cooperationEndAt"
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng chọn ngày kết thúc',
                      },
                    ]}
                  >
                    <DatePicker
                      onChange={(e) => console.log('change', e)}
                      onOk={(e) => console.log(e)}
                      placeholder="Chọn ngày"
                      disabledDate={disabledDate}
                      disabled={!isChooseDate}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <span>Người giới thiệu (SĐT)</span>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="presenterPhoneNumber"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const regexPhoneNumber = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;
                          if (!value || value.match(regexPhoneNumber)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Số điện thoại không hợp lệ'));
                        },
                      }),
                    ]}

                  >
                    <Input maxLength={10} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="d-flex justify-content-end">
            <Space>
              <FButton text={'Tạo mới'} htmlType="submit" loading={isLoading} />
            </Space>
          </div>
        </Form>
      </>
    </Modal>
  );
}

export default memo(ModalSalemanCreate);
