
import { QueryUtils } from "./../../../utils/index";
import { DELETE, GET, POST, PUT  } from "../../../api/method";
import { IPaging } from "./type";
export const orders = (params : IPaging) => {
  return GET(`order/order-queries/orders/get-all${QueryUtils.query(params)}`);
};

export const detail = (id : string) => {
  return GET(`order/order-queries/orders/${id}`);
};


export const create = (data : any) => {
  return POST(`order/order-commands/orders/add`,data);
};


export const search = (data : any) => {
  return POST(`order/order-queries/orders/search `,data);
};


export const update = (data : any) => {
  return PUT(`order/order-commands/orders/${data.id}/edit`,data);
};


export const deleteSaleman = (id : string) => {
  return DELETE(`order/order-commands/orders/${id}/remove`);
};

export const packages = () => {
  return GET(`order/order-queries/orders/get-packages`);
};

