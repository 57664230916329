import { RootState } from "middleware/stores";
import { useDispatch ,useSelector} from "react-redux";
import { checkSaleman,createSaleman,destroy,detailSalesman,getProfile,removeSalesman,saleman, searchSalesman, updateSaleman } from "./index";
import { IPaging, ReqCreateSaleMan } from "./type";


const useSaleman = ()  => {
  const dispatch = useDispatch() as any
  const onGetListSalesman = (params:IPaging ) => {
    dispatch(saleman(params))
  }

  const onGetDetail = (id:string ) => {
    dispatch(detailSalesman(id))
  }

  const onGetProfile = (id:string ) => {
    dispatch(getProfile(id))
  }

  const onRemove = (id:string ) => {
    dispatch(removeSalesman(id))
  }


  const onSearchSaleman = (data : any) => {
    dispatch(searchSalesman(data))
  }

  const onVerify = () => {
    dispatch(checkSaleman())
  }

  const onCreateSaleman = (data :ReqCreateSaleMan) => {
    dispatch(createSaleman(data))
  }

  const onUpdateSaleman = (data :ReqCreateSaleMan) => {
    dispatch(updateSaleman(data))
  }

  const onDestroy = () => {
    dispatch(destroy())
  }

  const {isLoading,isCheck,salemans,isSuccess,detail,total,profile,id,isSuccessCreate,isSuccessDelete,errorMessage} = useSelector((state: RootState) => state.persistedReducer.saleman);
  
  return  {onGetListSalesman,onGetProfile,onRemove,onDestroy,onGetDetail,onSearchSaleman,onCreateSaleman,onUpdateSaleman,id,onVerify,total,salemans,profile,detail,isCheck,isLoading,isSuccess,isSuccessCreate,isSuccessDelete,errorMessage}
};


export {useSaleman};
