function PolicyPage() {

  return (
    <>
      <object
        data={`https://directus.fidt.vn/assets/1d1da827-bf1c-4626-8650-13daf752de18?v=${Math.random()}#toolbar=0&navpanes=0&scrollbar=0`}
        width={'100%'}
        height={'100%'}
      />
    </>
  )
}

export default PolicyPage;
