import React from 'react';
import { Button } from "antd"
import type { SizeType } from 'antd/es/config-provider/SizeContext';
import { ButtonType } from 'antd/lib/button';
import { ButtonHTMLType } from 'antd/lib/button/button';

type Props = {
    style?: any
    size?: SizeType,
    className?: any
    type?: ButtonType
    danger?: boolean
    block?:boolean
    text: string
    onClick?:()=>void
    htmlType?: ButtonHTMLType
    loading ?: boolean,
    disabled?: boolean
}

const FButton = (props: Props) => {
    const { style, type = "primary", size = "large", text, loading = false, onClick, disabled=false, ...rest } = props
    return <Button type={type} size={size} onClick={onClick} {...rest} loading={loading} style={style} disabled={disabled}> 
        {text}
    </Button>
}

export default FButton;