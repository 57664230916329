import { FIcon } from "./../element";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import React, { useState, useMemo, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { icons } from "../../assets/icons/index";
import { useApp } from "../../middleware/state/app/hook";
import { ROLE } from "./../../constant";
import ModalAnalysisInfo from "./ModalAnalysisInfo";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem(
    "Chính sách hoa hồng",
    "/policy",
    <FIcon icon={icons.policy} className="menu-icon" />
  ),
  getItem(
    "Danh sách đơn hàng",
    "/order",
    <FIcon icon={icons.order} className="menu-icon" />
  ),
  getItem(
    "Mã voucher",
    "/voucher",
    <FIcon icon={icons.voucher} className="menu-icon" />
  ),
  getItem(
    "Thống kê",
    "/home",
    <FIcon icon={icons.dashboard} className="menu-icon" />
  ),
  getItem(
    "Quản lý sales",
    "/saleman",
    <FIcon icon={icons.users} className="menu-icon" />
  ),
];

const TheMenu: React.FC = () => {
  let location = useLocation();
  let navigate = useNavigate();
  const { role } = useApp();

  const [current, setCurrent] = useState(
    location.pathname === "/" || location.pathname === ""
      ? "/policy"
      : location.pathname
  );

  const [menu, setMenu] = useState(items);

  useEffect(() => {
    if (!role?.includes("CS")) {
      setMenu([
        ...items.filter(u => u?.key != "/saleman")
      ])
    }
  }, [])

  function handleClick(e: any) {
    if (e.key == '/home') {
      setIsModalAnalysisOpen(true);
      setMenuSelectedKey([current])
    }
    else {
      setMenuSelectedKey(e.key);
      setCurrent(e.key);
      navigate(e.key);
    }
  }

  const getOption = useMemo(() => {
    if (role?.includes(ROLE.CTV_SALES) && !role?.includes(ROLE.CS)) {
      return menu.filter((_, idx) => idx !== 3);
    }
    return menu;
  }, [role, menu]);

  const [menuSelectedKey, setMenuSelectedKey] = useState(['/policy']);

  const [isModalAnalysisOpen, setIsModalAnalysisOpen] = useState(false);

  useEffect(() => {{
    setMenuSelectedKey(['/policy']);
  }}, [])

  return (
    <>
      <ModalAnalysisInfo
        handleOk={() => setIsModalAnalysisOpen(false)}
        isModalOpen={isModalAnalysisOpen}
      />
      <Menu
        theme="dark"
        defaultSelectedKeys={[current]}
        mode="inline"
        items={getOption}
        selectedKeys={menuSelectedKey}
        onClick={handleClick}
        disabled={!role}
      />
    </>
  );
};

export default TheMenu;
