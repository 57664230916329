import { notification } from "antd";

type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const useNotify = () => {
  const [_, contextHolder] = notification.useNotification();

  const showNotify = (type: NotificationType,message:string) => {
    notification[type]({
      message:message
    })
  };
  return {showNotify,contextHolder}
};