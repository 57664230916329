import React, { useState } from "react";
import { Input } from "antd";
import type { SizeType } from "antd/es/config-provider/SizeContext";
import { ButtonType } from "antd/lib/button";
import { SearchOutlined } from "@ant-design/icons";
import { CloseCircleOutlined } from "@ant-design/icons";

type Props = {
  style?: any;
  size?: SizeType;
  className?: any;
  type?: ButtonType;
  danger?: boolean;
  block?: boolean;
  textholder?: string;
  onClick: (e: string) => void;
  value ?:string 
};
const FSearch = (props: Props) => {
  const { textholder, onClick } = props;
  const [key, setKey] = useState<any>("");
  return (
    <Input
      width={"100%"}
      placeholder={textholder}
      allowClear={{
        clearIcon: (
          <CloseCircleOutlined
            style={{ marginBottom: 5 }}
            onClick={() => onClick("")}
          />
        ),
      }}
      onKeyDown={(e)=>e.key==="Enter" && onClick(key)}
      onChange={(e: any) => {
        const value =  e.target.value
        setKey(value);
        if (value.length !== 0) {
          return;
        }
        onClick("");

      }}
      suffix={<SearchOutlined onClick={() => onClick(key)} />}
    />
  );
};

export default FSearch;
