/* eslint-disable no-unreachable */
export enum SALESMAN {
  SALESMAN_ACTIVE = "SALESMAN_ACTIVE",
  SALESMAN_STOP_COOPERATION = "SALESMAN_STOP_COOPERATION",
  SALESMAN_PAUSE_COOPERATION = "SALESMAN_PAUSE_COOPERATION",
  SALESMAN_TERMINATE_CONTRACT = "SALESMAN_TERMINATE_CONTRACT",
}

export enum ORDER {
  ORDER_DONE = "ORDER_DONE",
  ORDER_CANCELED = "ORDER_CANCELED",
  ORDER_IN_PROGRESS = "ORDER_IN_PROGRESS",
}

export enum SALEMAN_TEXT {
  SALESMAN_ACTIVE = "Đang hoạt động",
  SALESMAN_STOP_COOPERATION = "Yêu cầu dừng hợp tác",
  SALESMAN_PAUSE_COOPERATION = "Tạm dừng hợp tác",
  SALESMAN_TERMINATE_CONTRACT = "Chấm dứt hợp đồng",
}

export const SALESMAN_STATUS = [
  {
    id: 0,
    label: "Tất cả",
    value: "",
  },
  {
    id: 1,
    label: "Đang hoạt động",
    value: "SALESMAN_ACTIVE",
  },
  {
    id: 2,
    label: "Yêu cầu dừng hợp tác",
    value: "SALESMAN_STOP_COOPERATION",
  },
  {
    id: 3,
    label: "Tạm dừng hợp tác",
    value: "SALESMAN_PAUSE_COOPERATION",
  },
  {
    id: 4,
    label: "Chấm dứt hợp đồng",
    value: "SALESMAN_TERMINATE_CONTRACT",
  },
];

export const convertStatusSaleman = (status: string) => {
  switch (status) {
    case "SALESMAN_ACTIVE":
      return "Đang hoạt động";
      break;
    case "SALESMAN_STOP_COOPERATION":
      return "Yêu cầu dừng hợp tác";
      break;
    case "SALESMAN_PAUSE_COOPERATION":
      return "Tạm dừng hợp tác";
      break;
    case "SALESMAN_TERMINATE_CONTRACT":
      return "Chấm dứt hợp đồng";
      break;
    default:
      break;
  }
};

export const ORDER_STATUS = [
  {
    id: 0,
    label: "Tất cả",
    value: "",
  },
  {
    id: 1,
    label: "Đang xử lý",
    value: "ORDER_IN_PROGRESS",
  },
  {
    id: 2,
    label: "Bị Huỷ",
    value: "ORDER_CANCELED",
  },
  {
    id: 3,
    label: "Thành công",
    value: "ORDER_DONE",
  },
];

export const convertStatusOrder = (status: string) => {
  switch (status) {
    case "ORDER_IN_PROGRESS":
      return "Đang xử lý";
    case "ORDER_CANCELED":
      return "Bị Huỷ";
    case "ORDER_DONE":
      return "Thành công";
    default:
      break;
  }
};

export const ORDER_SERVICE_PACKAGE = [
  {
    id: 1,
    label: "Gói CCI",
    value: "CCI_PACKAGE",
  },
  {
    id: 2,
    label: "Gói tiêu chuẩn",
    value: "STANDARD_PACKAGE",
  },
  {
    id: 3,
    label: "Gói trải nghiệm",
    value: "EXPERIENCE_PACKAGE",
  },
  {
    id: 4,
    label: "Gói premium",
    value: "IDP_PREMIUM_PACKAGE",
  },
];

export const convertCCIPackage = (name: string) => {
  switch (name) {
    case "CCI_PACKAGE":
      return "Gói CCI";
      break;
    case "STANDARD_PACKAGE":
      return "Gói tiêu chuẩn";
      break;
    case "EXPERIENCE_PACKAGE":
      return "Gói trải nghiệm";
      break;
    case "IDP_PREMIUM_PACKAGE":
      return "Gói premium";
      break;
    default:
      break;
  }
};

export enum ROLE {
  ADMIN = "Admin",
  CTV_SALES = "CTV Sales",
  CS ="CS",
  FIDT_INTERNAL="FIDT_Internal"
}
