import React from "react";
import ReactDOM from "react-dom/client";
import "./app.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import "bootstrap/dist/css/bootstrap.css";
import store, { persistor } from "./middleware/stores";
import { PersistGate } from "redux-persist/integration/react";
import {ErrorBoundary} from 'react-error-boundary'

function ErrorFallback({error, resetErrorBoundary}:{error:any,resetErrorBoundary:any}) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ErrorBoundary
  FallbackComponent={ErrorFallback}
  onReset={() => {
    // reset the state of your app so the error doesn't happen again
  }}
>
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
  </ErrorBoundary>
);


reportWebVitals();
